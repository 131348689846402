<template>
      <div id="drawer" class="px-4 px-md-8 pt-8">
        <v-btn v-if="editEntry.createmode" class="findingssavebtn" tile big @click="createEntry()" > {{$t("create_element", { name: $t("entry") })}}</v-btn>
        <v-btn v-if="editmode" class="findingssavebtn" tile big @click="editingEntry(false)"> {{$t("save_changes")}}</v-btn>
          <!-- <h2>MOIN</h2>
          <h4>{{navObject.entry.title}}</h4> -->
        <div class="stickyheader pl-4 pl-md-8 row justify-content-between mx-0 pt-8 pb-7 mb-5">
          <div class="col white--text text-h5">{{navObject.entry.title == "" ? $t("create_element", { name: $t("entry") }) : navObject.entry.title}}</div>
            <div class="col-4 col-md-3 col-lg-3" style="display: flex;justify-content: end;">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <input ref="uploader" type="file" @change="onImagePreviewLoad" class="d-none inputfile inputfile-1" multiple v-show="editEntry.rightWriteEntries" accept=".jpg,.png,.jpeg,.gif,.svg" />
                  <v-btn v-bind="attrs" v-on="on" class="" :ripple="false" text fab small @click="upload()" :disabled="!editmode && !editEntry.createmode || !editEntry.rightWriteEntries">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17" style="filter: invert(100%) sepia(3%) saturate(1635%) hue-rotate(154deg) brightness(116%) contrast(100%);">
                      <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"></path>
                    </svg>
                  </v-btn>
                </template>
                <span>{{ $t("uploadImage") }}</span>
              </v-tooltip>
              <v-btn v-show="!editEntry.createmode" class="" :ripple="false" text fab small @click="makeEditable()"> <img width='100%' height='auto' src="../../assets/img/svg/icons/edit_white.svg" v-bind:class="[editmode || editEntry.createmode ? 'editable': '']" style="transform: scale(0.50);filter: invert(100%) sepia(3%) saturate(1635%) hue-rotate(154deg) brightness(116%) contrast(100%);"> </v-btn>
              <v-btn class="" :ripple="false" text fab small @click="close()"> <img width='100%' height='auto' src="../../assets/img/svg/icons/close_white.svg"> </v-btn>
            </div>
            
            
        </div>
        <div class="row justify-content-center align-items-center mx-0 pl-4 pl-md-8">
          <div v-show="editmode || editEntry.createmode" class="Findingimages col-12">
              {{$t('image')}} {{editEntry.entry.images.length > 0 ? currentImage + 1:'0'}}/{{editEntry.entry.images.length > 0 ?  editEntry.entry.images.length: '0'}}
              <div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <button :disabled="checkIfImageUploaded()" @click="setCurrentPreviewImage(editEntry.entry.images[currentImage])" v-bind="attrs" v-on="on" class="actionButton">
                        <img class="white-filter" style="transform: scale(1.2);" width='100%' height='auto' src="../../assets/img/svg/icons/image.svg">
                    </button>
                  </template>
                  <span>{{ checkIfImageUploaded() ? $t("markAsPreviewNotAllowed") : $t("markAsPreview") }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <button v-bind="attrs" v-on="on" class="actionButton float-right" @click="deleteCurrentImage(editEntry.entry.images[currentImage])">
                        <img class="white-filter"  style="transform:scale(0.8);" width='100%' height='auto' src="../../assets/img/svg/icons/trash.svg">
                    </button>
                  </template>
                  <span>{{ $t("deleteImage") }}</span>
                </v-tooltip>
              </div>
            </div>
          <div class="px-lg-16 col-9 col-lg-7 row mx-0 justify-content-center align-items-center">
            <div v-if="checkifshow">
              <v-carousel ref="carousel" v-model="currentImage"  height="100%" class="findingcarousel col-12" :show-arrows="editEntry.entry.images.length > 1">
                <v-carousel-item
                  v-for="(item,i) in editEntry.entry.images"
                  :key="i"
                >
                <div class="imagecontainer">
                  <img id="carouselimg" class="rounded-md" :src="item.url" alt="" @click="openImageInNewTab(item.url)">
                </div>
                </v-carousel-item>
                <!-- <div id="progress-container" v-if="!valuesAreSame" v-show="circleOn">
                  <v-progress-circular
                    color="blue-grey"
                    indeterminate
                  ></v-progress-circular>
                  <div class="text-white white ">AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA</div>
                </div> -->
                
              </v-carousel>
            </div>


            <!-- Placeholder for slow Loading -->
            
            <v-carousel ref="carousel" v-else-if="editEntry.entry.images.length < editEntry.imageCount && editEntry.entry.images.length > 0" v-model="currentImage" height="100%" class="findingcarousel col-12" :show-arrows="editEntry.entry.images.length > 1">
              
              
              <v-carousel-item
                v-for="(placeholder,j) in placeholderImages"
                :key="j"
              >
              <div v-if="editEntry.entry.images[j] == null || editEntry.entry.images[j].url == null || editEntry.entry.images[j] == 'undefined' || editEntry.entry.images[j].url == 'undefined'" class="h-200px d-flex justify-center align-center">
                <v-progress-circular
                  color="white"
                  indeterminate
                  size="64"
                  ></v-progress-circular>
              </div>
              <div v-else  class="imagecontainer">
                
                <img :src="editEntry.entry.images[j].url" id="carouselimg" class="rounded-md" alt="" @click="openImageInNewTab(editEntry.entry.images[j].url)">
              </div>
              </v-carousel-item>

              
            </v-carousel>

            
            <v-carousel v-else="editEntry.entry.images.length == 0" v-model="currentImage" height="100%" class="findingcarousel col-12" :show-arrows="false">
              <v-carousel-item >
              <div class="imagecontainer">
                <img style="background-color: #0F68FF" id="carouselimg" class="rounded-md" alt="">
              </div>
              </v-carousel-item>
              

            </v-carousel>
          </div>
        </div>
        <div class="stickysubheader row mx-0 pl-4 pl-md-8">
          <v-tabs class="findingtabs transparent" v-model="selectedEntryPopupTab">
            <v-btn @click="scrollToHeader(entryPopupTab)" :id="entryPopupTab + 'btn'" v-bind:class="{'shown': generalbtn}" class="h-100 mr-2 white--text" outlined v-for="entryPopupTab in getEntryPopupTabs()" :key="entryPopupTab">
                {{ $t(entryPopupTab) }}
            </v-btn>
          </v-tabs>
        </div>
        <div class="pt-9 align-items-end row mx-0 pl-4 pl-md-8">
          <div style="position: relative">
            <div ref="general" style="height: 50px; position: absolute; top: -250px; pointer-events: none;"></div>
          </div>
          <div v-intersect.quiet="highlightGeneral()" id="general" class="pl-0 col-12 white--text text-h6">
            {{ $t('general') }}
          </div>
          <div class="label text-uppercase pl-0 pt-0 col-md-2 col-3 white--text">
            {{ $t('designation') }}
          </div>
          <div class="pl-0 pt-0 col-9 white--text">
            <v-text-field
            :readonly="!editmode && !editEntry.createmode || !editEntry.rightWriteEntries"
            v-model="editEntry.entry.title"
            hide-details="auto"
            class="findingsinput"
            dense
            >
              <template v-slot:label>

              </template>
            </v-text-field>
          </div>

          <div class="label text-uppercase pl-0 pt-0 col-md-2 col-3 white--text">
            {{ $t('weapongroup') }}
          </div>
          <div class="pl-0 pt-0 col-9 white--text">
            <v-autocomplete
            :readonly="!editmode && !editEntry.createmode || !editEntry.rightWriteEntries"
            :items="localMetadata.weapongroups"
            v-model="editEntry.entry.group"
            return-object
            item-text='name'
            item-value='id'
            class="findingsinput"
            hide-details="auto"
            dense
            @change="weaponGroupChanged()"
            >
              <template v-slot:append>
                  <v-icon class="whiteicon scale-75" color="white">$chevrondown</v-icon>
              </template>
              <template slot="item" slot-scope="data">
                {{ $t(data.item.name)}}
              </template>
              <template slot="selection" slot-scope="data">
                {{ $t(data.item.name)}}
              </template>
              <template v-slot:label>

              </template>
            </v-autocomplete>
          </div>

          

          <div class="label text-uppercase pl-0 pt-0 col-md-2 col-3 white--text">
            {{ $t('weapontype') }}
          </div>
          <div class="pl-0 pt-0 col-9 white--text">
            <v-autocomplete
            :readonly="!editmode && !editEntry.createmode || !editEntry.rightWriteEntries"
            :items="localMetadata.selectableWeapontypes"
            v-model="editEntry.entry.type"
            return-object
            item-text='name'
            item-value='id'
            class="findingsinput"
            hide-details="auto"
            dense
            >
              <template v-slot:append>
                  <v-icon class="whiteicon scale-75" color="white">$chevrondown</v-icon>
              </template>
            <template slot="item" slot-scope="data">
                {{ $t(data.item.name)}}
              </template>
              <template slot="selection" slot-scope="data">
                {{ $t(data.item.name)}}
              </template>
              <template v-slot:label>

              </template>
            </v-autocomplete>
          </div>

          <div class="label text-uppercase pl-0 pt-0 col-md-2 col-3 white--text">
            {{ $t('weaponvariation') }}
          </div>
          <div class="pl-0 pt-0 col-9 white--text">
            <v-autocomplete
            :readonly="!editmode && !editEntry.createmode || !editEntry.rightWriteEntries"
            :items="localMetadata.selectableWeaponvariations"
            v-model="editEntry.entry.variation"
            return-object
            item-text='name'
            item-value='id'
            class="findingsinput"
            hide-details="auto"
            dense
            >
              <template v-slot:append>
                  <v-icon class="whiteicon scale-75" color="white">$chevrondown</v-icon>
              </template>
              <template slot="item" slot-scope="data">
                {{ $t(data.item.name)}}
              </template>
              <template slot="selection" slot-scope="data">
                {{ $t(data.item.name)}}
              </template>
              <template v-slot:label>

              </template>
            </v-autocomplete>
          </div>

          <div class="label text-uppercase pl-0 pt-0 col-md-2 col-3 white--text">
            {{ $t('detonators') }}
          </div>
          <div class="pl-0 pt-0 col-9 white--text">
            <v-autocomplete
            multiple
            :readonly="!editmode && !editEntry.createmode || !editEntry.rightWriteEntries"
            :items="detonators"
            v-model="editEntry.entry.detonators"
            return-object
            item-text='name'
            item-value='id'
            class="findingsinput"
            hide-details="auto"
            dense
            >
              <template v-slot:append>
                  <v-icon class="whiteicon scale-75" color="white">$chevrondown</v-icon>
              </template>
            <template slot="item" slot-scope="data">
                {{ $t(data.item.title)}}
              </template>
              <template slot="selection" slot-scope="data" >
                <div v-bind:class="{'clickable-selection' : !editmode && !editEntry.createmode}" @click="!editmode && !editEntry.createmode ? navigateToDetonator(data.item): null">
                  {{ $t(data.item.title)}}
                </div>
              </template>
              <template v-slot:label>

              </template>
            </v-autocomplete>
          </div>
          <div class="label text-uppercase pl-0 pt-0 col-md-2 col-3 white--text">
            {{ $t('entryType') }}
          </div>
          <div class="pl-0 pt-0 col-9 white--text">
            <v-autocomplete
            :readonly="!editmode && !editEntry.createmode || !editEntry.rightWriteEntries"
            :items="['Default', 'Detonator']"
            v-model="editEntry.entry.entryType"
            return-object
            class="findingsinput"
            hide-details="auto"
            dense
            >
              <template v-slot:append>
                  <v-icon class="whiteicon scale-75" color="white">$chevrondown</v-icon>
              </template>
            <template slot="item" slot-scope="data">
                {{$t(data.item)}}
              </template>
              <template slot="selection" slot-scope="data">
                {{ $t(data.item)}}
              </template>
              <template v-slot:label>
              </template>

            </v-autocomplete>
          </div>

          <div class="col-12 mx-0 px-0 row">
            <div class="label text-uppercase pl-0 pt-0 col-md-2 col-3 white--text">
              {{ $t('description') }}
            </div>
            <div class="pl-0 pt-0 col-9 white--text">
              <v-textarea
              :readonly="!editmode && !editEntry.createmode || !editEntry.rightWriteEntries"
              v-model="editEntry.entry.description"
              class="findingsinput"
              hide-details="auto"
              dense
              >
              </v-textarea>
            </div>
          </div>

          <div class="label text-uppercase pl-0 pt-0 col-md-2 col-3 white--text" v-show="(editEntry.rightPublishEntries || editEntry.rightWriteEntries) && editEntry.entry.createdByName != null && !editEntry.createmode">
            {{ $t('createdby') }}
          </div>
          <div class="pl-0 pt-0 col-9 white--text" v-show="(editEntry.rightPublishEntries || editEntry.rightWriteEntries) && editEntry.entry.createdByName != null && !editEntry.createmode">
            <v-text-field
            v-show="(editEntry.rightPublishEntries || editEntry.rightWriteEntries) && editEntry.entry.createdByName != null && !editEntry.createmode"
            :readonly="true"
            class="findingsinput"
            hide-details="auto"
            :value="editEntry.entry.createdByName"
            dense
            >
              <template v-slot:label>

              </template>
            </v-text-field>
          </div>

          <div class="label text-uppercase pl-0 pt-0 col-md-2 col-3 white--text" v-show="(editEntry.rightPublishEntries || editEntry.rightWriteEntries) && editEntry.entry.updatedAt != null">
            {{ $t('updatedby') }}
          </div>
          <div class="pl-0 pt-0 col-9 white--text" v-show="(editEntry.rightPublishEntries || editEntry.rightWriteEntries) && editEntry.entry.updatedAt != null">
            <v-text-field
            v-show="(editEntry.rightPublishEntries || editEntry.rightWriteEntries) && editEntry.entry.updatedAt != null"
            :readonly="true"
            class="findingsinput"
            hide-details="auto"
            dense
            :value="editEntry.entry.updatedAt == null ? '' :editEntry.entry.updatedByName == ' ' ? formatDate(editEntry.entry.updatedAt) :editEntry.entry.updatedByName + ', '+ formatDate(editEntry.entry.updatedAt)"
            >
            </v-text-field>
          </div>

          <div class="label text-uppercase pl-0 pt-0 col-md-2 col-3 white--text" v-if="!editEntry.createmode && (editEntry.rightPublishEntries || editEntry.rightWriteEntries)">
            {{ $t('status') }}
          </div>
          <div class="pl-0 pt-0 col-9 d-flex white--text" v-if="!editEntry.createmode && (editEntry.rightPublishEntries || editEntry.rightWriteEntries)">
            <v-text-field
            v-if="!editEntry.createmode && (editEntry.rightPublishEntries || editEntry.rightWriteEntries)"
            :readonly="true"
            class="findingsinput publish"
            hide-details="auto"
            dense
            :value="editEntry.entry.public ? $t('published'): $t('not_published')"
            >
              
            </v-text-field>
            <v-btn :disabled="!editmode && !editEntry.createmode" class="publishbtn white--text" tile outlined v-show="!editEntry.entry.public && editEntry.rightPublishEntries && editEntry.rightPublishOwnEntries && !editEntry.createmode" color="primary" text @click="editingEntry(true)">
              {{ $t("publish") }}
            </v-btn>
            <v-btn :disabled="!editmode && !editEntry.createmode" class="publishbtn white--text" tile outlined v-show="editEntry.entry.public && editEntry.rightPublishEntries && !editEntry.createmode" color="primary" text @click="editingEntry(false, true)">
              {{ $t("revoke_publish") }}
            </v-btn>
          </div>
          <div style="position: relative">
            <div ref="measurements" style="height: 50px; position: absolute; top: -250px; pointer-events: none;"></div>
          </div>
          <div v-intersect.quiet="highlightMeasurements()" id="measurements"  class="pl-0 col-12 white--text text-h6">
            {{ $t('measurements') }}
          </div>
          
          <div class="label text-uppercase pl-0 pt-0 col-md-2 col-3 white--text" >
            {{ $t('diameter') }}
          </div>
          <div class="pl-0 pt-0 col-9 white--text">
            <v-text-field
            :readonly="!editmode && !editEntry.createmode"
            v-model="editEntry.entry.measurements.diameter"
            hide-details="auto"
            class="findingsinput"
            dense
            suffix="mm"
            @keypress="filter(event)"
            
            >
              <template v-slot:label>

              </template>
            </v-text-field>
          </div>

          <div class="label text-uppercase pl-0 pt-0 col-md-2 col-3 white--text" >
            {{ $t('width') }}
          </div>
          <div class="pl-0 pt-0 col-9 white--text">
            <v-text-field
            :readonly="!editmode && !editEntry.createmode"
            v-model="editEntry.entry.measurements.width"
            hide-details="auto"
            class="findingsinput"
            dense
            suffix="mm"
            @keypress="filter(event)"
            
            >
              <template v-slot:label>

              </template>
            </v-text-field>
          </div>

          <div class="label text-uppercase pl-0 pt-0 col-md-2 col-3 white--text" >
            {{ $t('totalLength') }}
          </div>
          <div class="pl-0 pt-0 col-9 white--text">
            <v-text-field
            :readonly="!editmode && !editEntry.createmode"
            v-model="editEntry.entry.measurements.totalLength"
            hide-details="auto"
            class="findingsinput"
            dense
            suffix="mm"
            @keypress="filter(event)"
            
            >
              <template v-slot:label>

              </template>
            </v-text-field>
          </div>

          <div class="label text-uppercase pl-0 pt-0 col-md-2 col-3 white--text" >
            {{ $t('totalHeight') }}
          </div>
          <div class="pl-0 pt-0 col-9 white--text">
            <v-text-field
            :readonly="!editmode && !editEntry.createmode"
            v-model="editEntry.entry.measurements.totalHeight"
            hide-details="auto"
            class="findingsinput"
            dense
            suffix="mm"
            @keypress="filter(event)"
            
            >
              <template v-slot:label>

              </template>
            </v-text-field>
          </div>

          <div class="label text-uppercase pl-0 pt-0 col-md-2 col-3 white--text" >
            {{ $t('lengthOfBody') }}
          </div>
          <div class="pl-0 pt-0 col-9 white--text">
            <v-text-field
            :readonly="!editmode && !editEntry.createmode"
            v-model="editEntry.entry.measurements.lengthOfBody"
            hide-details="auto"
            class="findingsinput"
            dense
            suffix="mm"
            @keypress="filter(event)"
            
            >
              <template v-slot:label>

              </template>
            </v-text-field>
          </div>

          <div class="label text-uppercase pl-0 pt-0 col-md-2 col-3 white--text" >
            {{ $t('heightOfBody') }}
          </div>
          <div class="pl-0 pt-0 col-9 white--text">
            <v-text-field
            :readonly="!editmode && !editEntry.createmode"
            v-model="editEntry.entry.measurements.heightOfBody"
            hide-details="auto"
            class="findingsinput"
            dense
            suffix="mm"
            @keypress="filter(event)"
            
            >
              <template v-slot:label>

              </template>
            </v-text-field>
          </div>
          
          <div class="label text-uppercase pl-0 pt-0 col-md-2 col-3 white--text" >
            {{ $t('totalWeight') }}
          </div>
          <div class="pl-0 pt-0 col-9 white--text">
            <v-text-field
            :readonly="!editmode && !editEntry.createmode"
            v-model="editEntry.entry.measurements.totalWeight"
            hide-details="auto"
            class="findingsinput"
            dense
            suffix="g"
            @keypress="filter(event)"
            
            >
              <template v-slot:label>

              </template>
            </v-text-field>
          </div>

          <div class="label text-uppercase pl-0 pt-0 col-md-2 col-3 white--text" >
            {{ $t('fillingWeight') }}
          </div>
          <div class="pl-0 pt-0 col-9 white--text">
            <v-text-field
            :readonly="!editmode && !editEntry.createmode"
            v-model="editEntry.entry.measurements.fillingWeight"
            hide-details="auto"
            
            class="findingsinput"
            dense
            suffix="g"
            @keypress="filter(event)"
            >
              <template v-slot:label>

              </template>
            </v-text-field>
          </div>

          <div v-intersect.quiet="highlightAdditional()" id="additionalInfo" ref="additionalInfo" class="pl-0 col-12 white--text text-h6">
            {{ $t('additionalInfo') }}
          </div>

          <div class="label text-uppercase pl-0 pt-0 col-md-2 col-3 white--text">
            {{ $t('nation') }}
          </div>
          <div class="pl-0 pt-0 col-9 white--text">
            <v-autocomplete
            :readonly="!editmode && !editEntry.createmode"
            :items="localMetadata.nations"
            v-model="editEntry.entry.additionalInfo.nation"
            :item-value="item => item.id"
            :item-text="item => item.name"
            class="findingsinput"
            hide-details="auto"
            dense
            >
              <template v-slot:append>
                  <v-icon class="whiteicon scale-75" color="white">$chevrondown</v-icon>
              </template>
              <template v-slot:label>

              </template>
            </v-autocomplete>
          </div>

          <div class="label text-uppercase pl-0 pt-0 col-md-2 col-3 white--text">
            {{ $t('periodofuse') }}
          </div>
          <div class="pl-0 pt-0 col-9 white--text">
            <v-autocomplete
            :readonly="!editmode && !editEntry.createmode"
            :items="localMetadata.periodsofuse"
            v-model="editEntry.entry.additionalInfo.periodOfUse"
            return-object
            item-text='name'
            item-value='id'
            class="findingsinput"
            hide-details="auto"
            dense
            >
              <template v-slot:append>
                  <v-icon class="whiteicon scale-75" color="white">$chevrondown</v-icon>
              </template>
            <template slot="item" slot-scope="data">
                {{ $t(data.item.name)}}
              </template>
              <template slot="selection" slot-scope="data">
                {{ $t(data.item.name)}}
              </template>
              <template v-slot:label>

              </template>
            </v-autocomplete>
          </div>

          <div class="label text-uppercase pl-0 pt-0 col-md-2 col-3 white--text">
            {{ $t('propellant') }}
          </div>
          <div class="pl-0 pt-0 col-9 white--text">
            <v-autocomplete
            :readonly="!editmode && !editEntry.createmode"
            :items="localMetadata.propellants"
            v-model="editEntry.entry.additionalInfo.propellant"
            return-object
            item-text='name'
            item-value='id'
            class="findingsinput"
            hide-details="auto"
            dense
            >
              <template v-slot:append>
                  <v-icon class="whiteicon scale-75" color="white">$chevrondown</v-icon>
              </template>
            <template slot="item" slot-scope="data">
                {{ $t(data.item.name)}}
              </template>
              <template slot="selection" slot-scope="data">
                {{ $t(data.item.name)}}
              </template>
              <template v-slot:label>

              </template>
            </v-autocomplete>
          </div>

          <div class="label text-uppercase pl-0 pt-0 col-md-2 col-3 white--text">
            {{ $t('form') }}
          </div>
          <div class="pl-0 pt-0 col-9 white--text">
            <v-autocomplete
            :readonly="!editmode && !editEntry.createmode"
            :items="localMetadata.weaponforms"
            v-model="editEntry.entry.additionalInfo.form"
            return-object
            item-text='name'
            item-value='id'
            class="findingsinput"
            hide-details="auto"
            dense
            >
              <template v-slot:append>
                  <v-icon class="whiteicon scale-75" color="white">$chevrondown</v-icon>
              </template>
            <template slot="item" slot-scope="data">
                {{ $t(data.item.name)}}
              </template>
              <template slot="selection" slot-scope="data">
                {{ $t(data.item.name)}}
              </template>
              <template v-slot:label>

              </template>
            </v-autocomplete>
          </div>

          <div class="label text-uppercase pl-0 pt-0 col-md-2 col-3 white--text">
            {{ $t('advices') }}
          </div>
          <div class="pl-0 pt-0 col-9 white--text">
            <v-autocomplete
            :readonly="!editmode && !editEntry.createmode"
            :items="localMetadata.securityadvices"
            v-model="editEntry.entry.additionalInfo.securityAdvices"
            return-object
            item-text='name'
            item-value='id'
            class="findingsinput"
            hide-details="auto"
            dense
            multiple
            >
              <template v-slot:append>
                  <v-icon class="whiteicon scale-75" color="white">$chevrondown</v-icon>
              </template>
            <template slot="item" slot-scope="data">
                {{ $t(data.item.name)}}
              </template>
              <template slot="selection" slot-scope="data">
                {{ $t(data.item.name)}}
              </template>
              <template v-slot:label>

              </template>
            </v-autocomplete>
          </div>

          <div class="label text-uppercase pl-0 pt-0 col-md-2 col-3 white--text">
            {{ $t('materials') }}
          </div>
          <div class="pl-0 pt-0 col-9 white--text">
            <v-autocomplete
            :readonly="!editmode && !editEntry.createmode"
            :items="localMetadata.materials"
            v-model="editEntry.entry.additionalInfo.materials"
            return-object
            item-text='name'
            item-value='id'
            class="findingsinput"
            hide-details="auto"
            dense
            multiple
            >
              <template v-slot:append>
                  <v-icon class="whiteicon scale-75" color="white">$chevrondown</v-icon>
              </template>
            <template slot="item" slot-scope="data">
                {{ $t(data.item.name)}}
              </template>
              <template slot="selection" slot-scope="data">
                {{ $t(data.item.name)}}
              </template>
              <template v-slot:label>

              </template>
            </v-autocomplete>
          </div>

          <div class="label text-uppercase pl-0 pt-0 col-md-2 col-3 white--text">
            {{ $t('explosives') }}
          </div>
          <div class="pl-0 pt-0 col-9 white--text">
            <v-autocomplete
            :readonly="!editmode && !editEntry.createmode"
            :items="localMetadata.explosives"
            v-model="editEntry.entry.additionalInfo.filledWith"
            return-object
            item-text='name'
            item-value='id'
            class="findingsinput"
            hide-details="auto"
            dense
            multiple
            >
              <template v-slot:append>
                  <v-icon class="whiteicon scale-75" color="white">$chevrondown</v-icon>
              </template>
            <template slot="item" slot-scope="data">
                {{ $t(data.item.name)}}
              </template>
              <template slot="selection" slot-scope="data">
                {{ $t(data.item.name)}}
              </template>
              <template v-slot:label>

              </template>
            </v-autocomplete>
          </div>

          <div class="label text-uppercase pl-0 pt-0 col-md-2 col-3 white--text">
            {{ $t('drivingbands') }}
          </div>
          <div class="pl-0 pt-0 col-3 white--text">
            <v-text-field
            :readonly="!editmode && !editEntry.createmode"
            v-model="editEntry.entry.additionalInfo.drivingBand1"
            hide-details="auto"
            class="findingsinput"
            dense
            >
              <template v-slot:label>

              </template>
            </v-text-field>
          </div>
          <div class="pl-0 pt-0 col-3 white--text">
            <v-text-field
            :readonly="!editmode && !editEntry.createmode"
            v-model="editEntry.entry.additionalInfo.drivingBand2"
            hide-details="auto"
            class="findingsinput"
            dense
            >
              <template v-slot:label>

              </template>
            </v-text-field>
          </div>
          <div class="pl-0 pt-0 col-3 white--text">
            <v-text-field
            :readonly="!editmode && !editEntry.createmode"
            v-model="editEntry.entry.additionalInfo.drivingBand3"
            hide-details="auto"
            class="findingsinput"
            dense
            >
              <template v-slot:label>

              </template>
            </v-text-field>
          </div>


          <div id="sources" ref="sources" class="pl-0 col-12 white--text text-h6">
            {{ $t('sources') }}
          </div>
          

          <div class="pl-0 pt-0 col-md-2 col-3 white--text" >
            <v-btn icon color="error" @click="removeSourceFromEditedEntry()">
                <v-icon>fas fa-minus</v-icon>
            </v-btn>
            <v-btn icon color="white" @click="addEmptySourceToEditedEntry()">
                <v-icon>fas fa-plus</v-icon>
            </v-btn>
          </div>
          <div v-for="(source, index) in editEntry.entry.sources" :key="index" class="pl-0 pt-0 col-9 white--text">
            <v-text-field
            :readonly="!editmode && !editEntry.createmode"
            v-model="editEntry.entry.sources[index]"
            hide-details="auto"
            class="findingsinput"
            dense
            >
              <template v-slot:label>

              </template>
            </v-text-field>
          </div>

        </div>

        
      </div>
</template>

<script>

export default {

    props: {
    navObject: Object,
    editEntry: Object,
    metadata: Object,
    getEntryPopupTabs: Function,
    close: Function,
    reload: Function,
    },

    data() {
        return {
        dots: 6,
        routes: {
          entries: "api/entries",
        },
        deletedImages: [],
        currentImage: 0,
        currentImageNr: 0,
        selectedEntryPopupTab: null,
        detonators:[],
        totalCarouselItems: 0,

        generalbtn:false,
        editmode: false,
        circleOn: true,
        
        localMetadata: {}

        }
    },
    computed: {
      checkifshow(){
        return this.editEntry.entry.images.length > 0 && this.editEntry.imageCount <= this.editEntry.entry.images.length;
      },
      placeholderImages(){
        return Array.from({ length: this.editEntry.imageCount }, (_, index) => index);
      },
      valuesAreSame() {
      this.countCarouselItem();
      console.log(this.editEntry.entry.images.length === this.totalCarouselItems);
      console.log(this.editEntry.entry.images.length +" : " + this.totalCarouselItems);
      if(this.editEntry.imageCount === this.totalCarouselItems){
        this.circleOn = false;
      }else{
        this.circleOn = true;
      }
      // return this.editEntry.entry.images.length === this.totalCarouselItems;
      return false;
    },
    },

    watch: {
      'editEntry.entry.id': function(newValue, oldValue) {
          // Log the ID that changed
          console.log("CHANGED from" + oldValue, newValue);

          // Update detronators array
          this.detonators = this.localMetadata.detonators.filter(element => element.id !== newValue);

          // If there is a group in editEntry.entry
          if (this.editEntry.entry.group != null) {
            // Filter selectableWeaponTypes based on possibleSubTypes
            this.localMetadata.selectableWeapontypes = this.editEntry.entry.group.possibleSubTypes
              .flatMap(id => this.localMetadata.weapontypes.find(element => element.id === id))
              .filter(element => element != null);

            // Filter selectableWeaponVariations based on possibleVariations
            this.localMetadata.selectableWeaponvariations = this.editEntry.entry.group.possibleVariations
              .flatMap(id => this.localMetadata.weaponvariations.find(element => element.id === id))
              .filter(element => element != null);
          }
        },
        currentImage(newVal,oldVal){
          console.log('new:'+newVal + 'old:' + oldVal);
        },
        metadata: {
          handler(newMetadata){
            console.log("DATA RECEIVED",newMetadata);
            this.localMetadata = { ...newMetadata};
            console.log('localMetadata',this.localMetadata)
            if(this.editEntry.entry.group != null){
              this.localMetadata.selectableWeapontypes = this.editEntry.entry.group.possibleSubTypes
                        .flatMap(id => this.localMetadata.weapontypes.find(element => element.id === id))
                        .filter(element => element != null);

              this.localMetadata.selectableWeaponvariations = this.editEntry.entry.group.possibleVariations
                        .flatMap(id => this.localMetadata.weaponvariations.find(element => element.id === id))
                        .filter(element => element != null);
              
              console.log('this.localMetadata.selectableWeaponvariations#############',this.localMetadata.selectableWeaponvariations);
            }
          },
          immediate: true,
          deep:true,
        },
        'editEntry.entry.images': {
          handler(newValue, oldValue) {
            // This will be triggered if any item in myArray or its nested objects changes
            console.log('myArray changed:', newValue, oldValue);
            if(this.editEntry.imageCount === newValue.length){
              this.currentImage = 0;
            }
          },
        deep: true,
        },
        'editEntry.entry.variation': {
          immediate:true,
          handler(newValue) {
            console.log("editEntry.entry.variation changes",newValue)
            console.log(newValue && !this.localMetadata.selectableWeaponvariations.some((item) =>{
              console.log(item.name)
              return item.id === newValue.id
            }))
            console.log(!this.localMetadata.selectableWeaponvariations.some((item) => item.id === newValue.id))
            if (newValue && !this.localMetadata.selectableWeaponvariations.some((item) => item.id === newValue.id)) {
              // Add the selected value to the items array if not found
              console.log("inside",newValue)
              this.localMetadata.selectableWeaponvariations.push(newValue);
              console.log("this.localMetadata.selectableWeaponvariations",this.localMetadata.selectableWeaponvariations)
            }
          },
      },
      'editEntry.entry.type': {
          immediate:true,
          handler(newValue) {
            console.log("editEntry.entry.type changes",newValue)
            console.log(newValue && !this.localMetadata.selectableWeapontypes.some((item) =>{
              console.log(item.name)
              return item.id === newValue.id
            }))
            console.log(!this.localMetadata.selectableWeapontypes.some((item) => item.id === newValue.id))
            if (newValue && !this.localMetadata.selectableWeapontypes.some((item) => item.id === newValue.id)) {
              // Add the selected value to the items array if not found
              console.log("inside",newValue)
              this.localMetadata.selectableWeapontypes.push(newValue);
              console.log("this.localMetadata.selectableWeaponvariations",this.localMetadata.selectableWeapontypes)
            }
          },
      },
    },

    created(){
      console.log(Object.assign({},this.editEntry));
      console.log(Object.assign({},this.editEntry.entry));
      const count = this.editEntry.entry.images.length;
      console.log(count);
      let arr = this.editEntry.entry.images;
      console.log(Object.assign({},arr));
      // console.log(Object.assign({},this.editEntry.entry.images.length));
    },

    mounted() {
      console.log(Object.assign({},this.editEntry));
      const count = this.editEntry.entry.images.length;
      console.log(count);
      let array = this.editEntry.entry.images;
      console.log(Object.assign({},array));
      this.editmode = this.editEntry.editmode;
      console.log(this.localMetadata);
      console.log(this.editEntry);
      // this.detonators = this.metadata.detonators;
      // let arr = [];
      // for (let i = 0; i < this.detonators.length; i++) {
      //   const element = this.detonators[i];
      //   if(element.id != this.editEntry.entry.id){
      //     arr.push(element);
      //   }
      // }
      // this.detonators = arr;

      this.detonators = this.localMetadata.detonators.filter(element => element.id !== this.editEntry.entry.id);
      console.log(this.detonators, this.localMetadata.detonators);
      // for (let i = 0; i < this.editEntry.entry.images.length; i++) {
      //   const element = this.editEntry.entry.images[i];
      //   this.getImage(this.editEntry.entry, element);
      // }
      // if(this.editEntry.entry.group != null){
      //   this.localMetadata.selectableWeapontypes = this.editEntry.entry.group.possibleSubTypes
      //             .flatMap(id => this.localMetadata.weapontypes.find(element => element.id === id))
      //             .filter(element => element != null);

      //   this.localMetadata.selectableWeaponvariations = this.editEntry.entry.group.possibleVariations
      //             .flatMap(id => this.localMetadata.weaponvariations.find(element => element.id === id))
      //             .filter(element => element != null);
        
      //   console.log('this.localMetadata.selectableWeaponvariations#############',this.localMetadata.selectableWeaponvariations);
      // }

      // this.countCarouselItem();
    },

    methods:{
      insertDivIntoControls() {
        // Access the v-carousel-controls element
        const carouselControls = document.querySelector('.v-carousel__controls');

        // Create a new div element
        const newDiv = document.createElement('div');
        newDiv.innerHTML = `
        <div role="progressbar" aria-valuemin="0" aria-valuemax="100" class="v-progress-circular v-progress-circular--visible v-progress-circular--indeterminate blue-grey--text" style="height: 32px; width: 32px;"><svg xmlns="http://www.w3.org/2000/svg" viewBox="22.857142857142858 22.857142857142858 45.714285714285715 45.714285714285715" style="transform: rotate(0deg);"><circle fill="transparent" cx="45.714285714285715" cy="45.714285714285715" r="20" stroke-width="5.714285714285714" stroke-dasharray="125.664" stroke-dashoffset="125.66370614359172px" class="v-progress-circular__overlay"></circle></svg><div class="v-progress-circular__info"></div></div>
        `;

        // Append the new div to the v-carousel-controls
        carouselControls.appendChild(newDiv);
      },
      countCarouselItem(){
        this.$nextTick(() => {
        
        console.log(this.$refs.carousel.$children)
        const carouselItems = this.$refs.carousel.$children.filter(
            (child) => child.$options._componentTag === 'v-carousel-item'
          );
        this.totalCarouselItems = carouselItems.length;
        console.log(carouselItems)
        console.log(this.totalCarouselItems);
        
      });
      },
      async openImageInNewTab(imageUrl) {
      
        try {
        // Fetch the image as a Blob
        const response = await fetch(imageUrl);
        const blob = await response.blob();

        // Create a temporary URL for the blob
        const blobUrl = URL.createObjectURL(blob);

        // Open the blob URL in a new tab
        const newTab = window.open(blobUrl, "_blank");

        // If the tab successfully opened, revoke the blob URL when the new tab loads the image
        if (newTab) {
          newTab.onload = () => {
            URL.revokeObjectURL(blobUrl);
          };
        } else {
          console.error("Failed to open new tab. Please check pop-up blocker settings.");
        }
      } catch (error) {
        console.error("Failed to load the image:", error);
      }

      this.$nextTick(() => {
        

        const carouselItems = this.$refs.carousel.$children.filter(
            (child) => child.$options._componentTag === 'v-carousel-item'
          );
        this.totalCarouselItems = carouselItems.length;
        // console.log(carouselItems);
        
      });
      },
      openFullscreen(event) {

      
          const element = event.target;
          if (element.requestFullscreen) {
            element.requestFullscreen();
          } else if (element.mozRequestFullScreen) { // Firefox
            element.mozRequestFullScreen();
          } else if (element.webkitRequestFullscreen) { // Chrome, Safari and Opera
            element.webkitRequestFullscreen();
          } else if (element.msRequestFullscreen) { // IE/Edge
            element.msRequestFullscreen();
          }
        },
      navigateToDetonator(detonator){
        // console.log(detonator);
       if(detonator.id == this.editEntry.entry.id) return null

       this.editEntry.entry = detonator;

      },
      scrollToHeader(entryPopupTab){
        
        var drawer = document.getElementById("drawer");

        var element = document.getElementById(entryPopupTab);
        var position = element.getBoundingClientRect().top - 50;

        // console.log(element);
        // console.log(drawer)

        this.$refs[entryPopupTab].scrollIntoView({ behavior: 'smooth',block: 'start'});
      },
      _validate() {
      if (this.editEntry.entry.group == null) {
        this.$noty.error(this.$t("empty_value", { name: this.$t("weapongroups") }));
        return false;
      }
      return true;
    },
      filter(evt) {
      evt = (evt) ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();
      
      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
      onError(err) {
        if (err.response != null && err.response.data != null) {
          this.$noty.error('<i class="fas fa-minus-circle mr-3"></i>' + this.$t(err.response.data.message));
        } else {
          console.error(err);
        }
      },
      highlightGeneral(entries, observer, isIntersecting){
      },
      highlightMeasurements(){

      },
      highlightAdditional(){

      },
      highlightSources(entries, observer, isIntersecting){
      },
      createEntry() {
        if(!this._validate()) return;
          var saveEntry = this.convertEntryToRequest(this.editEntry.entry);

          
          var _this = this;
          this.$axios
          .post(this.routes.entries, saveEntry)
          .then(function (response) {
            _this.$noty.success('<i class="fas fa-check-circle mr-3"></i>' + _this.$t("entry_created"));
            
            const entry = response.data;
            
            _this.uploadImages(saveEntry.id, _this.editEntry.entry.images).then(function () {
              
            });

            setTimeout(() => {
                    _this.reload();
                    },2000)
                    
            _this.close();
                  
                  // _this.resetEditedEntry();
              })
              .catch(this.onError);
      },
      makeEditable(){
        this.editmode = true;
      },
      editingEntry(publish = false, revokePublish = false) {
          var _this = this;
          
          var saveEntry = this.convertEntryToRequest(this.editEntry.entry);
          if (publish) {
              saveEntry.public = true;
          }
          if (revokePublish) {
              saveEntry.public = false;
          }

          // saveEntry.previewImage = this.editEntry.previewImage;

          this.$axios
              .put(this.routes.entries, saveEntry)
              .then(function (response) {
                  _this.$noty.success('<i class="fas fa-check-circle mr-3"></i>' + _this.$t("entry_edited"));
                  
                  const entry = response.data;
                  
                  
                  // if (!(_this.editedEntry.previewImage == null)){
                    //     _this.updatePreviewImage(entry.id, _this.editedEntry.previewImage);
                  //     console.log("_this.editedEntry.previewImage:" + _this.editedEntry.previewImage)
                  // }
                  setTimeout(() => {
                    _this.reload();
                    },2000)

                  // _this.resetEditedEntry();
                  _this.uploadImages(entry.id, _this.editEntry.entry.images).then(function () {
                  });
                  _this.deleteImages(entry.id, _this.deletedImages);
                  _this.close();

              })
              .catch(this.onError);
      },
      reloadEntries(){
        this.reload();
      },
      upload(){
        this.$refs.uploader.click();
      },
      resetEditedEntry(filter = false) {
          // Filter nicht zurücksetzen nach schließen und erneutem Öffnen
          
          this.editEntry.entry = {
              id: null,
              title: "",
              description: "",
              measurements: {
                  diameter: 0,
                  width: 0,
                  totalLength: 0,
                  totalHeight: 0,
                  lengthOfBody: 0,
                  heightOfBody: 0,
                  totalWeight: 0,
                  fillingWeight: 0,
              },
              additionalInfo: {
                  nation: null,
                  periodOfUse: null,
                  drivingBand1: [],
                  drivingBand2: [],
                  drivingBand3: [],
                  explosiveBoosters: [],
                  propellant: null,
                  documents: [],
                  form: null,
                  securityAdvices: [],
                  materials: [],
                  filledWith: [],
              },
              sources: [""],
              images: [],
              rawImages: {},
              detonators: [],
              previewImage: null,
          };
          this.deletedImages = [];
          this.currentPreviewImage.page = 0;
          this.currentPreviewImage.url = this.blankImage;

          if (filter) {
              this.editEntry.entry.additionalInfo.nation = null;
          }

          // For Filter
          this.editEntry.entry.measurementsFrom = this.copy(this.editEntry.entry.measurements);
          this.editEntry.entry.measurementsTo = this.copy(this.editEntry.entry.measurements);

          this.weaponGroupChanged();
      },
      weaponGroupChanged() {
          // if (this.editmode) {
          //     return;
          // }

          if (this.editEntry.entry.group == null) {
              this.localMetadata.selectableWeapontypes = this.localMetadata.weapontypes;
              this.localMetadata.selectableWeaponvariations = this.localMetadata.weaponvariations;

          } else {
              const group = this.editEntry.entry.group;
              this.localMetadata.selectableWeapontypes = group.possibleSubTypes
                  .flatMap(id => this.localMetadata.weapontypes.find(element => element.id === id))
                  .filter(element => element != null);
              
                this.localMetadata.selectableWeaponvariations = group.possibleVariations
                  .flatMap(id => this.localMetadata.weaponvariations.find(element => element.id === id))
                  .filter(element => element != null);

              if (!this.localMetadata.selectableWeapontypes.includes(this.editEntry.entry.type)) {
                  this.editEntry.entry.type = null;
              }

              if(!this.localMetadata.selectableWeaponvariations.includes(this.editEntry.entry.variation)){
                this.editEntry.entry.variation = null;
              }
          }
          // this.weaponTypeChanged();
      },

      weaponTypeChanged() {
          // if (this.editmode) {
          //     return;
          // }

          if (this.editEntry.entry.type == null) {
              if (this.localMetadata.selectableWeapontypes.find(element => element.possibleSubTypes.length === 0)) {
                  this.localMetadata.selectableWeaponvariations = this.localMetadata.weaponvariations;
              } else {
                  this.localMetadata.selectableWeaponvariations = this.localMetadata.weaponvariations.filter(variation => this.localMetadata.selectableWeapontypes.find(type => type.possibleSubTypes.includes(variation.id)));
              }
          } else {
              const type = this.editEntry.entry.type;
              this.localMetadata.selectableWeaponvariations = type.possibleSubTypes
                  .flatMap(id => this.localMetadata.weaponvariations.find(element => element.id === id))
                  .filter(element => element != null);
              if (!this.localMetadata.selectableWeaponvariations.includes(this.editEntry.entry.variation)) {
                  this.editEntry.entry.variation = null;
              }
          }
      },
      optionalObjectToId(obj) {
          return obj == null ? 0 : obj.id;
      },
      flatMapId(attributes) {
          var arr = [];
          if (attributes == null || attributes.length == 0) return arr;
          attributes.forEach((attr) => {
              arr.push(attr.id);
          });
          return arr;
      },
      addEmptySourceToEditedEntry() {
          this.editEntry.entry.sources.push("");
      },

      removeSourceFromEditedEntry() {
          if (this.editEntry.entry.sources.length <= 1) return;
          this.editEntry.entry.sources.splice(this.editEntry.entry.sources.length - 1, 1);
      },
      convertEntryToRequest(entry) {
          // entry.drivingBands = this.convertDrivingBands(entry);

          const result = {
              id: entry.id,
              title: entry.title,
              description: entry.description,
              groupId: this.optionalObjectToId(entry.group),
              typeId: this.optionalObjectToId(entry.type),
              variationId: entry.variation == null ? 0 : entry.variation.id,
              detonatorIds: this.flatMapId(entry.detonators),
              entryType: entry.entryType,
              additionalInfo: {
                  formId: this.optionalObjectToId(entry.additionalInfo.form),
                  propellantId: this.optionalObjectToId(entry.additionalInfo.propellant),
                  nation: entry.additionalInfo.nation,
                  materialIds: this.flatMapId(entry.additionalInfo.materials),
                  securityAdviceIds: this.flatMapId(entry.additionalInfo.securityAdvices),
                  periodOfUseId: this.optionalObjectToId(entry.additionalInfo.periodOfUse),
                  filledWithIds: this.flatMapId(entry.additionalInfo.filledWith),
                  explosiveBoosters: entry.explosiveBoosters,
                  drivingBand1: entry.additionalInfo.drivingBand1,
                  drivingBand2: entry.additionalInfo.drivingBand2,
                  drivingBand3: entry.additionalInfo.drivingBand3,
                  documentIds: this.flatMapId(entry.additionalInfo.documents)
              },
              measurements: entry.measurements,
              sources: entry.sources,
              images: entry.images,
              // previewImage: this.editEntry.entry.previewImage ? this.editEntry.entry.previewImage.image : entry.previewImage.name =! null ? {image:entry.previewImage.name} :entry.previewImage.image, // Take old Preview Image
              // previewImage: entry.previewImage.name =! null ? {image:entry.previewImage.name} :entry.previewImage.image,
              // previewImage: entry.previewImage && typeof entry.previewImage == "object" ? entry.previewImage.name : entry.previewImage,
          };
          return result;
      },
      convertDrivingBands(entry){
        let drivingbands = [];
        drivingbands.push(entry.additionalInfo.drivingBand1);
        drivingbands.push(entry.additionalInfo.drivingBand2);
        drivingbands.push(entry.additionalInfo.drivingBand3);
        return drivingbands;
      },
      deleteCurrentImage(image) {
          const img = this.editEntry.entry.images[this.currentImage];
          this.deletedImages.push(img.name);
          this.editEntry.entry.images.splice(this.currentImage, 1);
          this.editEntry.imageCount =- 1 ;
          // Bild löschen, wenn bereits auf Server gespeichert
          // if (typeof this.currentPreviewImage.url != "object") {
              
          //     this.$axios
          //         .delete(this.currentPreviewImage.url)
          //         .then(function (response) {})
          //         .catch(this.onError);
          // }

          

          // this.currentPreviewImage.page = 0;
          // this.currentPreviewImage.url = this.blankImage;
          // this.previousPreviewImage();
      },
      onImagePreviewLoad(e) {
          if (e.target.files.length == 0) return;
          for (let i = 0; i < e.target.files.length; i++) {
              const file ={
                file: e.target.files[i],
                url: URL.createObjectURL(e.target.files[e.target.files.length - 1])
                } 
              // console.log(this.editEntry);
              this.editEntry.entry.images.push(file);
          }
      },
      uploadImages(entryId, images) {
          const actualImages = images.filter(element => element.file !== null && typeof(element.file) == 'object');
          

          var promises = [];

          if (actualImages.length > 0) {
              actualImages.forEach(element => {
                  promises.push(this.uploadSingleImage(entryId, element.file));
              });
          }

          return Promise.all(promises);
      },
      uploadSingleImage(entryId, file) {
          var data = new FormData();
          data.append("file", file || file.fileName);
          return this.$axios
              .post(this.routes.entries + "/" + entryId + "/images", data, {
                  headers: {
                      accept: "application/json",
                      "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
                  },
              });
      },
      deleteImages(entryid, deletedImages ,functionToCall = null) {
          var _this = this;

          for (let i = 0; i < deletedImages.length; i++) {
              this.$axios
                  .delete("api/entries/" + entryid + "/images/" + deletedImages[i])
                  .catch(err => {
                      console.error(err);
                  });
          }
      },
      retrieveImage(entry, image) {
          const url = 'api/entries' + "/" + entry.id + "/images/" + image;

          return new Promise((resolve, reject) => {
              this.$axios.get(url, {
                  responseType: 'blob'
              }).then(function (response) {
                  const reader = new window.FileReader();
                  reader.readAsDataURL(response.data);
                  reader.onload = function () {
                      resolve(reader.result);
                  }
                  reader.onerror = reject;
              }).catch(function (error) {
                  reject(error);
              });
          });
      },
      getImage(entry, image) {
            if (entry.images != null && entry.images[image] == null) {
                // this.currentPreviewImage.url = this.blankImage;
                // this.currentPreviewImage.name = image;
                // this.downloadingImage = true;
                const self = this;

                this.retrieveImage(entry, image).then(function (result) {
                    // self.downloadingImage = false;
                    self.editEntry.entry.images[image] = result;
                    // self.currentPreviewImage.url = result;
                }).catch(this.imageNotFound);
            } else if(entry.images != null){
                // this.currentPreviewImage.url = entry.images[image];
            }
        },
        checkIfImageUploaded(){
          const img = this.editEntry.entry.images[this.currentImage];
          // console.log(this.currentImage);
          // console.log(this.editEntry.entry.images);
          // console.log(this.editEntry.entry.images[this.currentImage]);
          if(img == null) return false;
          if(typeof img.file != 'undefined' && typeof img.file == 'object' ){
            return true;
          }
          return false;
        },
        updatePreviewImage(entryid, prevImage) {
            var _this = this;
            
            this.$axios
                .patch("api/entries/" + entryid + "/images/preview", {image:prevImage}).then(function(response){
                  _this.editEntry.entry.previewImage = {image:prevImage};
                  _this.reload();
                })
                .catch(this.onError);
        },
        setCurrentPreviewImage(image){
          if(this.checkIfImageUploaded()) return;
          // console.log(image);
          this.updatePreviewImage(this.editEntry.entry.id,image.name);
          // this.editEntry.entry.previewImage.name = image.name;
          // this.editEntry.entry.previewImage = image.url;
        },
        formatDate(dateString) {
            if (dateString == null || dateString.length == 0) return;
            var date = new Date(dateString);
            var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
            var month = date.getMonth() < 10 ? "0" + date.getMonth() : date.getMonth();
            var year = date.getFullYear();
            var hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
            var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
            var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

            return day + "." + month + "." + year + " " + hour + ":" + minutes + " " + this.$t("h");
        },

    }
}
</script>

<style>
.v-select__slot .clickable-selection{
    margin-left: 5px;
    border: 1px solid #333;
    padding: 2px;
    border-radius: 5px;
}
.clickable-selection:hover{
  cursor: pointer;
  color: gray !important;
}
#progress-container{
  position: absolute;
  right: 0;
  bottom: -40px;
}
.h-200px{
  height: 200px;
}
</style>