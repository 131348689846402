<template>
<div class="px-6 px-lg-14 pt-6 pt-lg-10">
    <!-- Top Bar -->
        <!-- <button class="filterButton" @click=" (createmode = true); (filtermode = true); (createEntryPopup = true); weaponGroupChanged(); ">
            <img width='70%' height='auto' src="../assets/img/svg/icons/filter.svg">
        </button> -->
        <!-- <button class="addButton" @click="
            (createmode = true),
              (filtermode = false),
              (createEntryPopup = true),
              resetEditedEntry()
          ">
            <img width='70%' height='auto' src="../assets/img/svg/icons/add.svg">
        </button> --> 

    <v-btn
      class="addButton"
      fab
      dark
      large
      elevation="0"
      color="black"
      @click.stop="
            openDrawer(null,false,true),
              resetEditedEntry()"
    >
      <img width='35%' height='auto' src="../assets/img/svg/icons/add.svg">
    </v-btn>



    <div class="mx-0 pb-6 pb-lg-10 row align-items-end">
        <div class="text-h4 text-uppercase" style="display: inline-block;" >{{ $t("objects") }}<div class="text-body-1 ml-1" style="display: inline-block;" >({{totalEntries}})</div></div>
    </div>
    <div class="row mx-0 justify-content-between">
        <!-- <v-select
        hide-details="auto"
        :items="measurementFieldsDistance"
        class="rounded-0"
        :label="'Form'"
        multiple
        :menu-props="{ bottom: true, offsetY: true }"
        outlined>
            <template v-slot:append>
                <img src="../assets/img/svg/icons/chevron-down.svg" alt="">
            </template>
        </v-select> -->

        <div class="col-12 pa-0">

            <v-menu
            v-model="filterGroupMenu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-y
            flat>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    v-bind:class="{'open': filterGroupMenu}"
                    :ripple="false"
                    v-bind="attrs"
                    v-on="on"
                    hide-details="auto"
                    class="mr-2 mt-2 rounded-0 filterbtn"
                    outlined>
                        {{$t('weapongroup')}}
                        <v-icon right class="scale-75 filtericon" color="white" height="16px">$chevrondown</v-icon>
                    </v-btn>
                </template>
                <v-card class="filtercard rounded-0" color="#161616">
                    <v-list class="transparent">
                        <v-list-item-group multiple v-model="filtEntry.group" @change="searchEntry()">
                            <template v-for="(item, i) in weapongroups">
                                <v-list-item
                                :key="`item-${i}`"
                                :value="item"
                                class=""
                                >
                                    <template v-slot:default="{ active }">
                                        <v-list-item-action class="checkbox">
                                            <v-checkbox
                                            :input-value="active"
                                            color="deep-purple accent-4"
                                            ></v-checkbox>
                                        </v-list-item-action>

                                        <v-list-item-content>
                                            <v-list-item-title class="white--text" v-text="item.name"></v-list-item-title>
                                        </v-list-item-content>

                                    </template>
                                </v-list-item>
                            </template>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-menu>

            <v-menu
            v-model="filterTypeMenu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-y
            flat>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    v-bind:class="{'open': filterTypeMenu}"
                    :ripple="false"
                    v-bind="attrs"
                    v-on="on"
                    hide-details="auto"
                    class="mr-2 mt-2 rounded-0 filterbtn"
                    outlined>
                        {{$t('weapontype')}}
                        <v-icon right class="scale-75 filtericon" color="white" height="16px">$chevrondown</v-icon>
                    </v-btn>
                </template>
                <v-card class="filtercard rounded-0" color="#161616">
                    <v-list class="transparent">
                        <v-list-item-group multiple v-model="filtEntry.type" @change="searchEntry()">
                            <template v-for="(item, i) in filtEntry.group.length == 0 ? weapontypes : possibleWeapontypesFilter">
                                <v-list-item
                                :key="`item-${i}`"
                                :value="item"
                                class=""
                                >
                                    <template v-slot:default="{ active }">
                                        <v-list-item-action class="checkbox">
                                            <v-checkbox
                                            :input-value="active"
                                            color="deep-purple accent-4"
                                            ></v-checkbox>
                                        </v-list-item-action>

                                        <v-list-item-content>
                                            <v-list-item-title class="white--text" v-text="item.name"></v-list-item-title>
                                        </v-list-item-content>

                                    </template>
                                </v-list-item>
                            </template>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-menu>

            <v-menu
            v-model="filterVariationMenu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-y
            flat>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    v-bind:class="{'open': filterVariationMenu}"
                    :ripple="false"
                    v-bind="attrs"
                    v-on="on"
                    hide-details="auto"
                    class="mr-2 mt-2 rounded-0 filterbtn"
                    outlined>
                        {{$t('weaponvariation')}}
                        <v-icon right class="scale-75 filtericon" color="white" height="16px">$chevrondown</v-icon>
                    </v-btn>
                </template>
                <v-card class="filtercard rounded-0" color="#161616">
                    <v-list class="transparent">
                        <v-list-item-group multiple v-model="filtEntry.variation" @change="searchEntry()">
                            <template v-for="(item, i) in filtEntry.group.length == 0 ? weaponvariations : possibleWeaponvariationsFilter">
                                <v-list-item
                                :key="`item-${i}`"
                                :value="item"
                                class=""
                                >
                                    <template v-slot:default="{ active }">
                                        <v-list-item-action class="checkbox">
                                            <v-checkbox
                                            :input-value="active"
                                            color="deep-purple accent-4"
                                            ></v-checkbox>
                                        </v-list-item-action>

                                        <v-list-item-content>
                                            <v-list-item-title class="white--text" v-text="item.name"></v-list-item-title>
                                        </v-list-item-content>

                                    </template>
                                </v-list-item>
                            </template>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-menu>

            <v-menu
            v-model="filterDetonatorMenu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-y
            flat>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    v-bind:class="{'open': filterDetonatorMenu}"
                    :ripple="false"
                    v-bind="attrs"
                    v-on="on"
                    hide-details="auto"
                    class="mr-2 mt-2 rounded-0 filterbtn"
                    outlined>
                        {{$t('detonator')}}
                        <v-icon right class="scale-75 filtericon" color="white" height="16px">$chevrondown</v-icon>
                    </v-btn>
                </template>
                <v-card class="filtercard rounded-0" color="#161616">
                    <v-list class="transparent">
                        <v-list-item-group multiple v-model="filtEntry.detonators" @change="searchEntry()">
                            <template v-for="(item, i) in detonators">
                                <v-list-item
                                :key="`item-${i}`"
                                :value="item"
                                class=""
                                >
                                    <template v-slot:default="{ active }">
                                        <v-list-item-action class="checkbox">
                                            <v-checkbox
                                            :input-value="active"
                                            color="deep-purple accent-4"
                                            ></v-checkbox>
                                        </v-list-item-action>

                                        <v-list-item-content>
                                            <v-list-item-title class="white--text" v-text="item.title"></v-list-item-title>
                                        </v-list-item-content>

                                    </template>
                                </v-list-item>
                            </template>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-menu>

            <v-menu
            v-model="filterMaterialMenu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-y
            flat>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    v-bind:class="{'open': filterMaterialMenu}"
                    :ripple="false"
                    v-bind="attrs"
                    v-on="on"
                    hide-details="auto"
                    class="mr-2 mt-2 rounded-0 filterbtn"
                    outlined>
                        {{$t('material')}}
                        <v-icon right class="scale-75 filtericon" color="white" height="16px">$chevrondown</v-icon>
                    </v-btn>
                </template>
                <v-card class="filtercard rounded-0" color="#161616">
                    <v-list class="transparent">
                        <v-list-item-group multiple v-model="filtEntry.additionalInfo.materials" @change="searchEntry()">
                            <template v-for="(item, i) in materials">
                                <v-list-item
                                :key="`item-${i}`"
                                :value="item"
                                class=""
                                >
                                    <template v-slot:default="{ active }">
                                        <v-list-item-action class="checkbox">
                                            <v-checkbox
                                            :input-value="active"
                                            color="deep-purple accent-4"
                                            ></v-checkbox>
                                        </v-list-item-action>

                                        <v-list-item-content>
                                            <v-list-item-title class="white--text" v-text="item.name"></v-list-item-title>
                                        </v-list-item-content>

                                    </template>
                                </v-list-item>
                            </template>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-menu>

            <v-menu
              v-model="filterPeriodMenu"
              :close-on-content-click="false"
              :nudge-width="200"
              offset-y
              flat>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind:class="{'open': filterPeriodMenu}"
                  :ripple="false"
                  v-bind="attrs"
                  v-on="on"
                  hide-details="auto"
                  class="mr-2 mt-2 rounded-0 filterbtn"
                  outlined>
                {{$t('periodofuse')}}
                <v-icon right class="scale-75 filtericon" color="white" height="16px">$chevrondown</v-icon>
              </v-btn>
            </template>
            <v-card class="filtercard rounded-0" color="#161616">
              <v-list class="transparent">
                <v-list-item-group multiple v-model="filtEntry.additionalInfo.periodOfUse" @change="searchEntry()">
                  <template v-for="(item, i) in periodsofuse">
                    <v-list-item
                        :key="`item-${i}`"
                        :value="item"
                        class=""
                    >
                      <template v-slot:default="{ active }">
                        <v-list-item-action class="checkbox">
                          <v-checkbox
                              :input-value="active"
                              color="deep-purple accent-4"
                          ></v-checkbox>
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title class="white--text" v-text="item.name"></v-list-item-title>
                        </v-list-item-content>

                      </template>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-menu>

            <v-menu
            v-show="morefilter"
            v-model="filterNationMenu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-y
            flat>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    v-show="morefilter"
                    v-bind:class="{'open': filterNationMenu}"
                    :ripple="false"
                    v-bind="attrs"
                    v-on="on"
                    hide-details="auto"
                    class="mr-2 mt-2 rounded-0 filterbtn"
                    outlined>
                        {{$t('nation')}}
                        <v-icon right class="scale-75 filtericon" color="white" height="16px">$chevrondown</v-icon>
                    </v-btn>
                </template>
                <v-card class="filtercard rounded-0" color="#161616">
                    <v-list class="transparent">
                        <v-list-item-group v-model="filtEntry.additionalInfo.nation" @change="searchEntry()">
                            <template v-for="(item, i) in nations">
                                <v-list-item
                                :key="`item-${i}`"
                                :value="item"
                                class=""
                                >
                                    <template v-slot:default="{ active }">
                                        <v-list-item-action class="checkbox">
                                            <v-checkbox
                                            :input-value="active"
                                            color="deep-purple accent-4"
                                            ></v-checkbox>
                                        </v-list-item-action>

                                        <v-list-item-content>
                                            <v-list-item-title class="white--text" v-text="item.name"></v-list-item-title>
                                        </v-list-item-content>

                                    </template>
                                </v-list-item>
                            </template>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-menu>

            <v-menu
            v-show="morefilter"
            v-model="filterDiameterMenu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-y
            flat>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    v-show="morefilter"
                    v-bind:class="{'open': filterDiameterMenu}"
                    :ripple="false"
                    v-bind="attrs"
                    v-on="on"
                    hide-details="auto"
                    class="mr-2 mt-2 rounded-0 filterbtn"
                    outlined>
                        {{$t('diameter')}}
                        <v-icon right class="scale-75 filtericon" color="white" height="16px">$chevrondown</v-icon>
                    </v-btn>
                </template>
                <v-card class="filtercard range rounded-0" color="#161616">
                    <div class="row mx-0 py-4">
                        <span v-if="filtEntry.measurements.diameter != null" class="white--text col-12 text-center">{{filtEntry.measurements.diameter[0]}} mm - {{filtEntry.measurements.diameter[1]}} mm</span>
                        <v-range-slider
                        v-model="filtEntry.measurements.diameter"
                        color="#FFFFFF"
                        :max="max"
                        :min="min"
                        step="10"
                        hide-details="auto"
                        class="pt-0 col-12 align-center"
                        @change="searchEntry()"
                        ></v-range-slider>
                    </div>
                </v-card>
            </v-menu>

            <v-menu
            v-show="morefilter"
            v-model="filterWidthMenu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-y
            flat>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    v-show="morefilter"
                    v-bind:class="{'open': filterWidthMenu}"
                    :ripple="false"
                    v-bind="attrs"
                    v-on="on"
                    hide-details="auto"
                    class="mr-2 mt-2 rounded-0 filterbtn"
                    outlined>
                        {{$t('width')}}
                        <v-icon right class="scale-75 filtericon" color="white" height="16px">$chevrondown</v-icon>
                    </v-btn>
                </template>
                <v-card class="filtercard range rounded-0" color="#161616">
                    <div class="row mx-0 py-4">
                        <span v-if="filtEntry.measurements.width != null" class="white--text col-12 text-center">{{filtEntry.measurements.width[0]}} mm - {{filtEntry.measurements.width[1]}} mm</span>

                        <v-range-slider
                        v-model="filtEntry.measurements.width"
                        color="#FFFFFF"
                        :max="max"
                        :min="min"
                        step="10"
                        hide-details="auto"
                        class="pt-0 col-12 align-center"
                        @change="searchEntry()"
                        ></v-range-slider>
                    </div>
                </v-card>
            </v-menu>

            <v-menu
            class="filtermenu rounded-0"
            v-show="morefilter"
            v-model="filterLengthOfBodyMenu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-y
            flat>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    v-show="morefilter"
                    v-bind:class="{'open': filterLengthOfBodyMenu}"
                    :ripple="false"
                    v-bind="attrs"
                    v-on="on"
                    hide-details="auto"
                    class="mr-2 mt-2 rounded-0 filterbtn"
                    outlined>
                        {{$t('lengthOfBody')}}
                        <v-icon right class="scale-75 filtericon" color="white" height="16px">$chevrondown</v-icon>
                    </v-btn>
                </template>
                <v-card class="filtercard range rounded-0" color="#161616">
                    <div class="row mx-0 py-4">
                        <span class="white--text col-12 text-center">{{filtEntry.measurements.lengthOfBody[0]}} mm - {{filtEntry.measurements.lengthOfBody[1]}} mm</span>
                        <v-range-slider
                        v-model="filtEntry.measurements.lengthOfBody"
                        color="#FFFFFF"
                        :max="max"
                        :min="min"
                        step="10"
                        hide-details="auto"
                        class="pt-0 col-12 align-center"
                        @change="filterEntry"
                        ></v-range-slider>
                    </div>
                </v-card>
            </v-menu>

            <v-menu
            class="filtermenu rounded-0"
            v-show="morefilter"
            v-model="filterHeightOfBodyMenu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-y
            flat>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    v-show="morefilter"
                    v-bind:class="{'open': filterHeightOfBodyMenu}"
                    :ripple="false"
                    v-bind="attrs"
                    v-on="on"
                    hide-details="auto"
                    class="mr-2 mt-2 rounded-0 filterbtn"
                    outlined>
                        {{$t('heightOfBody')}}
                        <v-icon right class="scale-75 filtericon" color="white" height="16px">$chevrondown</v-icon>
                    </v-btn>
                </template>
                <v-card class="filtercard range rounded-0" color="#161616">
                    <div class="row mx-0 py-4">
                        <span class="white--text col-12 text-center">{{filtEntry.measurements.heightOfBody[0]}} mm - {{filtEntry.measurements.heightOfBody[1]}} mm</span>
                        <v-range-slider
                        v-model="filtEntry.measurements.heightOfBody"
                        color="#FFFFFF"
                        :max="max"
                        :min="min"
                        step="10"
                        hide-details="auto"
                        class="pt-0 col-12 align-center"
                        @change="filterEntry"
                        ></v-range-slider>
                    </div>
                </v-card>
            </v-menu>

            <v-menu
            class="filtermenu rounded-0"
            v-show="morefilter"
            v-model="filterTotalLengthMenu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-y
            flat>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    v-show="morefilter"
                    v-bind:class="{'open': filterTotalLengthMenu}"
                    :ripple="false"
                    v-bind="attrs"
                    v-on="on"
                    hide-details="auto"
                    class="mr-2 mt-2 rounded-0 filterbtn"
                    outlined>
                        {{$t('totalLength')}}
                        <v-icon right class="scale-75 filtericon" color="white" height="16px">$chevrondown</v-icon>
                    </v-btn>
                </template>
                <v-card class="filtercard range rounded-0" color="#161616">
                    <div class="row mx-0 py-4">
                        <span class="white--text col-12 text-center">{{filtEntry.measurements.totalLength[0]}} mm - {{filtEntry.measurements.totalLength[1]}} mm</span>
                        <v-range-slider
                        v-model="filtEntry.measurements.totalLength"
                        color="#FFFFFF"
                        :max="max"
                        :min="min"
                        step="10"
                        hide-details="auto"
                        class="pt-0 col-12 align-center"
                        @change="filterEntry"
                        ></v-range-slider>
                    </div>
                </v-card>
            </v-menu>

            <v-menu
                class="filtermenu rounded-0"
                v-show="morefilter"
                v-model="filterTotalHeightMenu"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-y
                flat>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    v-show="morefilter"
                    v-bind:class="{'open': filterTotalHeightMenu}"
                    :ripple="false"
                    v-bind="attrs"
                    v-on="on"
                    hide-details="auto"
                    class="mr-2 mt-2 rounded-0 filterbtn"
                    outlined>
                        {{$t('totalHeight')}}
                        <v-icon right class="scale-75 filtericon" color="white" height="16px">$chevrondown</v-icon>
                    </v-btn>
                </template>
                <v-card class="filtercard range rounded-0" color="#161616">
                    <div class="row mx-0 py-4">
                        <span class="white--text col-12 text-center">{{filtEntry.measurements.totalHeight[0]}} mm - {{filtEntry.measurements.totalHeight[1]}} mm</span>
                        <v-range-slider
                        v-model="filtEntry.measurements.totalHeight"
                        color="#FFFFFF"
                        :max="max"
                        :min="min"
                        step="10"
                        hide-details="auto"
                        class="pt-0 col-12 align-center"
                        @change="filterEntry"
                        ></v-range-slider>
                    </div>
                </v-card>
            </v-menu>

            <v-menu
                class="filtermenu rounded-0"
                v-show="morefilter"
                v-model="filterDrivingbandsMenu"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-y
                flat>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    v-show="morefilter"
                    v-bind:class="{'open': filterDrivingbandsMenu}"
                    :ripple="false"
                    v-bind="attrs"
                    v-on="on"
                    hide-details="auto"
                    class="mr-2 mt-2 rounded-0 filterbtn"
                    outlined>
                        {{$t('drivingbands')}}
                        <v-icon right class="scale-75 filtericon" color="white" height="16px">$chevrondown</v-icon>
                    </v-btn>
                </template>
                <v-card class="filtercard range rounded-0" color="#161616">
                    <div class="row mx-0 py-4">
                        <!-- <span class="white--text col-12 text-center">1.{{$t('drivingband')}}</span> -->
                        <span class="white--text col-12 text-center">{{filtEntry.additionalInfo.drivingBand1[0]}} mm - {{filtEntry.additionalInfo.drivingBand1[1]}} mm</span>
                        <v-range-slider
                        v-model="filtEntry.additionalInfo.drivingBand1"
                        color="#FFFFFF"
                        :max="max"
                        :min="min"
                        step="10"
                        hide-details="auto"
                        class="pt-0 col-12 align-center"
                        @change="filterEntry"
                        ></v-range-slider>
                        <!-- <span class="white--text col-12 text-center">2.{{$t('drivingband')}}</span> -->
                        <span class="white--text col-12 text-center">{{filtEntry.additionalInfo.drivingBand2[0]}} mm - {{filtEntry.additionalInfo.drivingBand2[1]}} mm</span>
                        <v-range-slider
                        v-model="filtEntry.additionalInfo.drivingBand2"
                        color="#FFFFFF"
                        :max="max"
                        :min="min"
                        step="10"
                        hide-details="auto"
                        class="pt-0 col-12 align-center"
                        @change="filterEntry"
                        ></v-range-slider>
                        <!-- <span class="white--text col-12 text-center">3.{{$t('drivingband')}}</span> -->
                        <span class="white--text col-12 text-center">{{filtEntry.additionalInfo.drivingBand3[0]}} mm - {{filtEntry.additionalInfo.drivingBand3[1]}} mm</span>
                        <v-range-slider
                        v-model="filtEntry.additionalInfo.drivingBand3"
                        color="#FFFFFF"
                        :max="max"
                        :min="min"
                        step="10"
                        hide-details="auto"
                        class="pt-0 col-12 align-center"
                        @change="filterEntry"
                        ></v-range-slider>
                    </div>
                </v-card>
            </v-menu>

            <v-btn
              :ripple="false"
              hide-details="auto"
              class="mr-2 mt-2 rounded-0 filterbtn"
              @click="morefilter = !morefilter"
              outlined>
            {{ morefilter ? $t('less_filter'):$t('more_filter')}}
            <v-icon v-show="!morefilter" right class="scale-75 filtericon" style="transform: scale(1.8);">$add</v-icon>
            <v-icon v-show="morefilter" right class="scale-75 filtericon" size="16">fas fa-minus</v-icon>
          </v-btn>

          <v-menu
            class="sortingmenu mr-2"
            v-model="filterSortMenu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-y
            left
            min-width="unset"
            flat>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    v-bind:class="{'open': filterSortMenu}"
                    :ripple="false"
                    v-bind="attrs"
                    v-on="on"
                    hide-details="auto"
                    class="mt-2 rounded-0 filterbtn"
                    outlined>
                        {{$t('sort')}}
                        <v-icon right class="scale-75 filtericon" color="white" height="16px">$chevrondown</v-icon>
                    </v-btn>
                </template>
                <v-card class="filtercard rounded-0" color="#161616">
                    <v-list class="transparent">
                        <v-list-item-group class="sortinggroup" v-model="sort" @change="page=1,searchEntry()">
                            <template v-for="(item, i) in sortings">
                                <v-list-item
                                :key="`item-${i}`"
                                :value="item.value"
                                class="selectsort"
                                >
                                    <template v-slot:default="{}">
                                        <v-list-item-action class="mr-2 checkbox">
                                            <!-- <v-checkbox
                                            :input-value="active"
                                            color="deep-purple accent-4"
                                            ></v-checkbox> -->
                                        </v-list-item-action>

                                        <v-list-item-content>
                                            <v-list-item-title class="white--text" v-text="$t(item.name)"></v-list-item-title>
                                        </v-list-item-content>

                                    </template>
                                </v-list-item>
                            </template>
                        </v-list-item-group>
                        <v-list-item-group v-model="order" @change="page=1,searchEntry()">
                            <template v-for="(item, i) in orderings">
                                <v-list-item
                                :key="`item-${i}`"
                                :value="item.value"
                                >
                                    <template v-slot:default="{ active }">
                                        <v-list-item-action class="mr-2 checkbox">
                                            <!-- <v-checkbox
                                            :input-value="active"
                                            color="deep-purple accent-4"
                                            ></v-checkbox> -->
                                            <v-icon size="16" v-show="active">
                                                fa-thin fa-check
                                            </v-icon>
                                        </v-list-item-action>

                                        <v-list-item-content>
                                            <v-list-item-title class="white--text" v-text="$t(item.name)"></v-list-item-title>
                                        </v-list-item-content>

                                    </template>
                                </v-list-item>
                            </template>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-menu>
        </div>

        <div v-if="checkFilterChips()" class="col-12 px-0">
            <v-chip-group class="filterchips">
                <v-chip v-for="(filter,i) in filtEntry.group" :key="i">
                    {{filter.name}}
                    <v-icon @click="removeFilterEntry('weapongroups',filter)" right>$close</v-icon>
                </v-chip>
                <v-chip v-for="(filter,i) in filtEntry.type" :key="i">
                    {{filter.name}}
                    <v-icon @click="removeFilterEntry('weapontypes',filter)" right>$close</v-icon>
                </v-chip>
                <v-chip v-for="(filter,i) in filtEntry.variation" :key="i">
                    {{filter.name}}
                    <v-icon @click="removeFilterEntry('weaponvariation',filter)" right>$close</v-icon>
                </v-chip>
                <v-chip v-for="(filter,i) in filtEntry.detonators" :key="i">
                    {{filter.title}}
                    <v-icon @click="removeFilterEntry('detonators',filter)" right>$close</v-icon>
                </v-chip>
                <v-chip v-for="(filter,i) in filtEntry.additionalInfo.materials" :key="i">
                    {{filter.name}}
                    <v-icon @click="removeFilterEntry('materials',filter)" right>$close</v-icon>
                </v-chip>
                <v-chip v-for="(filter,i) in filtEntry.additionalInfo.periodOfUse" :key="i">
                    {{filter.name}}
                    <v-icon @click="removeFilterEntry('periodOfUse',filter.id)" right>$close</v-icon>
                </v-chip>
                <v-chip v-if="filtEntry.additionalInfo.nation != null">
                    {{$t(filtEntry.additionalInfo.nation.name)}}
                    <v-icon @click="removeFilterEntry('nations')" right>$close</v-icon>
                </v-chip>
                <v-chip v-if="filtEntry.measurements.diameter[1] > 0">
                    {{$t('diameter')}} {{filtEntry.measurements.diameter[0]}} - {{filtEntry.measurements.diameter[1]}}
                    <v-icon @click="removeFilterEntry('diameter')" right>$close</v-icon>
                </v-chip>
                <v-chip v-if="filtEntry.measurements.width[1] > 0">
                    {{$t('width')}} {{filtEntry.measurements.width[0]}} - {{filtEntry.measurements.width[1]}}
                    <v-icon @click="removeFilterEntry('width')" right>$close</v-icon>
                </v-chip>
                <v-chip v-if="filtEntry.measurements.lengthOfBody[1] > 0">
                    {{$t('lengthOfBody')}} {{filtEntry.measurements.lengthOfBody[0]}} - {{filtEntry.measurements.lengthOfBody[1]}}
                    <v-icon @click="removeFilterEntry('lengthOfBody')" right>$close</v-icon>
                </v-chip>
                <v-chip v-if="filtEntry.measurements.heightOfBody[1] > 0">
                    {{$t('heightOfBody')}} {{filtEntry.measurements.heightOfBody[0]}} - {{filtEntry.measurements.heightOfBody[1]}}
                    <v-icon @click="removeFilterEntry('heightOfBody')" right>$close</v-icon>
                </v-chip>
                <v-chip v-if="filtEntry.measurements.totalLength[1] > 0">
                    {{$t('totalLength')}} {{filtEntry.measurements.totalLength[0]}} - {{filtEntry.measurements.totalLength[1]}}
                    <v-icon @click="removeFilterEntry('totalLength')" right>$close</v-icon>
                </v-chip>
                <v-chip v-if="filtEntry.measurements.totalHeight[1] > 0">
                    {{$t('totalHeight')}} {{filtEntry.measurements.totalHeight[0]}} - {{filtEntry.measurements.totalHeight[1]}}
                    <v-icon @click="removeFilterEntry('totalHeight')" right>$close</v-icon>
                </v-chip>
                <v-chip v-if="filtEntry.additionalInfo.drivingBand1[1] > 0">
                    1.{{$t('drivingbands')}} {{filtEntry.additionalInfo.drivingBand1[0]}} - {{filtEntry.additionalInfo.drivingBand1[1]}}
                    <v-icon @click="removeFilterEntry('drivingBand1')" right>$close</v-icon>
                </v-chip>
                <v-chip v-if="filtEntry.additionalInfo.drivingBand2[1] > 0">
                    2.{{$t('drivingbands')}}  {{filtEntry.additionalInfo.drivingBand2[0]}} - {{filtEntry.additionalInfo.drivingBand2[1]}}
                    <v-icon @click="removeFilterEntry('drivingBand2')" right>$close</v-icon>
                </v-chip>
                <v-chip v-if="filtEntry.additionalInfo.drivingBand3[1] > 0">
                    3.{{$t('drivingbands')}}  {{filtEntry.additionalInfo.drivingBand3[0]}} - {{filtEntry.additionalInfo.drivingBand3[1]}}
                    <v-icon @click="removeFilterEntry('drivingBand3')" right>$close</v-icon>
                </v-chip>
                <v-chip @click="removeFilterEntry('all')" class="resetfilter">
                    alle löschen
                </v-chip>
            </v-chip-group>
        </div>


        <div class="col-12 col-md-6 col-lg-4 px-0">
            <v-text-field
            hide-details="auto"
            v-model="search"
            dense
            :prepend-icon="'fas fa-search'"
             @change="searchEntry()"
            >
            <!-- @input="searchEntry()" -->
              <template v-slot:label>

              </template>
            </v-text-field>
        </div>

    </div>

    <div class="page-content">
        <v-simple-table id="Findings" class="findingstable">
            <thead>
                <tr>
                    <th class="align-bottom">

                    </th>
                    <th class="align-bottom">
                        {{ $t("designation") }}
                    </th>
                    <th class="align-bottom">
                        {{ $t("weapongroup") }}
                    </th>
                    <th class="align-bottom">
                        {{ $t("nation") }}
                    </th>
                    <th class="col-1 align-bottom" >
                        {{ $t("images") }}
                    </th>
                    <th class="col-1 align-bottom" v-show="rightPublishEntries">
                        {{ $t("published") }}
                    </th>
                    <th class="col-1 align-bottom">Aktion</th>
                </tr>
            </thead>
            <tbody>
                <tr @click="openDrawer(entry,false,false),selectedFinding = copy(entry)" v-bind:class="{selected: selectedFinding == null ? false :selectedFinding.id == entry.id ? true:false}" v-for="entry in entries" :key="entry.id">
                    <td class="pa-0 tableimgtd" >
                        <div class="tableimg my-1">
                            <img style="background-color: #D8D8D8;" class="rounded-md" :src="getPreviewImage(entry,entry.previewImage)" alt="">
                        </div>
                    </td>
                    <td>{{ entry.title }}</td>
                    <td>{{ unwrapName(entry.group) }}</td>
                    <td>{{extractNation(entry.additionalInfo.nation)}}</td>
                    <td><img src="../assets/img/svg/icons/image.svg" alt=""> {{entry.images.length}}</td>
                    <td v-show="rightPublishEntries">
                        <v-checkbox disabled v-model="entry.public" />
                    </td>
                    <td class="mobile-table-row">

                        <!-- <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon class="mr-5 actionButton" v-bind="attrs" v-on="on" :disabled=" (entry.previewImage == null || entry.previewImage === '') && entry.images.length === 0 " @click="showPreviewImage(entry)" color="black">fas fa-images</v-icon>
                            </template>
                            <span>{{ $t("imagePreview") }}</span>
                        </v-tooltip> -->

                        <button class="actionButton" v-show="rightWriteEntries" @click.stop="openDrawer(entry,true,false),selectedFinding = copy(entry)">
                            <img width='100%' height='auto' src="../assets/img/svg/icons/edit.svg">
                        </button>

                        <v-icon class="mr-5 actionButton" @click.stop=" (createmode = false), (filtermode = false), getEditedEntry(entry) " color="black" v-show="!rightWriteEntries">fas fa-info</v-icon>

                        <button class="actionButton float-right" @click.stop="showConfirmation($t('confirm_delete_entry'), function () {deleteEntry(entry.id);})">
                            <img width='100%' height='auto' src="../assets/img/svg/icons/trash.svg">
                        </button>

                    </td>
                </tr>
            </tbody>
        </v-simple-table>
        <div style="position:absolute; bottom: 500px;left:50%;height:40px;width:40px " v-intersect.quiet="infiniteScrolling"></div>
        <!-- <div class="selectedImage d-none d-lg-block" v-bind:class="{'hidden': selectedFinding == null || currentPreviewImage == null || currentPreviewImage.url == null || currentPreviewImage.url == '' || currentPreviewImage.url == blankImage }">
            <img :src="selectedFinding == null ? null : currentPreviewImage.url" alt="">
        </div> -->

        <!-- Create Entry Popup -->
        <!-- <v-dialog v-model="createEntryPopup" persistent max-width="900px">
            <v-card v-if="editedEntry != null">
                <v-card-title>
                    
                    <span v-show="createmode && !filtermode" class="headline">{{
                $t("create_element", { name: $t("entry") })
              }}</span>
                    
                    <span v-show="createmode && filtermode" class="headline">{{
                $t("filter")
              }}</span>
                    
                    <span v-show="!createmode && rightWriteEntries" class="headline">{{
                $t("edit_element", { name: editedEntry.title })
              }}</span>
                    >
                    <span v-show="!createmode && !rightWriteEntries" class="headline">{{
                editedEntry.title
              }}</span>
                </v-card-title>
                <v-card-text>
                    <v-tabs v-model="selectedEntryPopupTab">
                        <v-tab v-for="entryPopupTab in getEntryPopupTabs()" :key="entryPopupTab">
                            {{ $t(entryPopupTab) }}
                        </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="selectedEntryPopupTab">
                        
                        <v-tab-item>
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field v-show="filtermode" :label="$t('designation') + (filtermode ? '' : '*')" v-model="editedEntry.title" :rules="filtermode ? [] : rules">
                                        </v-text-field>
                                        <v-text-field v-show="!filtermode" :label="$t('designation') + '*'" v-model="editedEntry.title" :readonly="!filtermode && !rightWriteEntries" :rules="rules">
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-autocomplete :label="$t('weapongroup')" :items="weapongroups" item-value="id" item-text="name" return-object v-model="editedEntry.group" :readonly="!filtermode && !rightWriteEntries" @change="weaponGroupChanged()" :multiple="filtermode">
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-autocomplete :label="$t('weaponvariation')" :items="selectableWeaponvariations" item-value="id" item-text="name" return-object v-model="editedEntry.variation" :readonly="!filtermode && !rightWriteEntries" :multiple="filtermode">
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-autocomplete :label="$t('weapontype')" :items="selectableWeapontypes" item-value="id" item-text="name" return-object v-model="editedEntry.type" :readonly="!filtermode && !rightWriteEntries" @change="weaponTypeChanged()" :multiple="filtermode">
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-autocomplete :label="$t('detonators')" :items="detonators" item-value="id" item-text="name" return-object v-model="editedEntry.detonators" :readonly="!filtermode && !rightWriteEntries" multiple>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-textarea :label="$t('description')" v-model="editedEntry.description" rows="3" :readonly="!filtermode && !rightWriteEntries">
                                        </v-textarea>
                                    </v-col>
                                    <v-col cols="6" v-show="(rightPublishEntries || rightWriteEntries) &&!filtermode">
                                        <v-label>{{ $t("createdby") }}:
                                            {{ editedEntry.createdByName }}</v-label>
                                    </v-col>
                                    <v-col cols="6" v-show="!filtermode">
                                        <v-label>{{ $t("status") }}:
                                            {{editedEntry.public ? $t("published"): $t("not_published")}}</v-label>
                                        <v-btn v-show="!createmode && !filtermode && rightPublishEntries && (rightPublishOwnEntries || editedEntry.createdById != me.id) && !editedEntry.public" color="primary" text @click="editEntry(true)">
                                                {{ $t("publish") }}
                                        </v-btn>
                                        <v-btn v-show="!createmode && !filtermode && rightPublishEntries && editedEntry.public" color="primary" text @click="editEntry(false, true)">
                                            {{ $t("revoke_publish") }}
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="6" v-show="(rightPublishEntries || rightWriteEntries) && !filtermode && editedEntry.updatedAt != null">
                                        <v-label>{{ $t("updatedby") }}:
                                            {{ editedEntry.updatedByName }}
                                        </v-label>
                                    </v-col>
                                    <v-col cols="6" v-show="(rightPublishEntries || rightWriteEntries) && !filtermode && editedEntry.updatedAt != null">
                                        <v-label>{{ $t("updatedat") }}:
                                            {{ formatDate(editedEntry.updatedAt) }}
                                        </v-label>
                                    </v-col>
                                    <v-col cols="6" v-show="(rightPublishEntries || rightWriteEntries) &&!filtermode && editedEntry.public">
                                        <v-label>{{ $t("releasedby") }}:
                                            {{ editedEntry.releasedByName }}
                                        </v-label>
                                    </v-col>
                                    <v-col cols="6" v-show="(rightPublishEntries || rightWriteEntries) &&!filtermode && editedEntry.public">
                                        <v-label>{{ $t("releasedat") }}:
                                            {{ formatDate(editedEntry.releasedAt) }}
                                        </v-label>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-tab-item>

                        
                        <v-tab-item>
                            <v-container>
                                <v-row>
                                    <v-col cols="3" class="padding-top-switch">
                                        <v-label>{{ $t("switchUnits") }}:</v-label>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-switch :label="`${measurementLengthSuffix}`" v-model="measurementUnitLengthSelected" @change="changeUnit('length')">
                                        </v-switch>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-switch :label="`${measurementWeightSuffix}`" v-model="measurementUnitWeightSelected" @change="changeUnit('weight')">
                                        </v-switch>
                                    </v-col>
                                    <v-col cols="3"> &nbsp; </v-col>
                                    <v-col cols="6" v-for="measurementField in measurementFieldsDistance" :key="measurementField">
                                        <v-text-field v-show="!filtermode" :label="$t(measurementField)" v-model="editedEntry.measurements[measurementField]" :readonly="!rightWriteEntries" :suffix="measurementLengthSuffix" @change="onKeyDownNumberField(measurementField)">
                                        </v-text-field>
                                        <v-row v-if="filtermode && editedEntry.measurementsFrom && editedEntry.measurementsTo">
                                            <v-col cols="12" style="padding-bottom: 0px">
                                                <v-label>{{ $t(measurementField) }}:</v-label>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field :label="$t('from')" v-model="editedEntry.measurementsFrom[measurementField]" :suffix="measurementLengthSuffix" @change="onKeyDownNumberField(measurementField)">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field :label="$t('to')" v-model="editedEntry.measurementsTo[measurementField]" :suffix="measurementLengthSuffix" @change="onKeyDownNumberField(measurementField)">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="6" v-for="measurementField in measurementFieldsWeight" :key="measurementField">
                                        <v-text-field :label="$t(measurementField)" v-model="editedEntry.measurements[measurementField]" :readonly="!rightWriteEntries" :suffix="measurementWeightSuffix" @change="onKeyDownNumberField(measurementField)">
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-tab-item>

                        
                        <v-tab-item>
                            <v-container>
                                <v-row>
                                    <v-col cols="6">
                                      <v-autocomplete :label="$t('nations')" :items="nations" item-value="id" item-text="name" return-object v-model="editedEntry.additionalInfo.nation" clearable :readonly="!filtermode && !rightWriteEntries">
                                      </v-autocomplete>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-autocomplete :label="$t('periodofuse')" :items="periodsofuse" item-value="id" item-text="name" return-object v-model="editedEntry.additionalInfo.periodOfUse" clearable :readonly="!filtermode && !rightWriteEntries">
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-autocomplete :label="$t('propellant')" :items="propellants" item-value="id" item-text="name" return-object v-model="editedEntry.additionalInfo.propellant" clearable :readonly="!filtermode && !rightWriteEntries">
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-autocomplete :label="$t('weaponform')" :items="weaponforms" item-value="id" item-text="name" return-object v-model="editedEntry.additionalInfo.form" clearable :readonly="!filtermode && !rightWriteEntries">
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-autocomplete :label="$t('securityadvices')" :items="securityadvices" item-value="id" item-text="name" return-object v-model="editedEntry.additionalInfo.securityAdvices" :readonly="!filtermode && !rightWriteEntries" multiple>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-autocomplete :label="$t('materials')" :items="materials" item-value="id" item-text="name" return-object v-model="editedEntry.additionalInfo.materials" :readonly="!filtermode && !rightWriteEntries" multiple>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-autocomplete :label="$t('explosive')" :items="explosives" item-value="id" item-text="name" return-object v-model="editedEntry.additionalInfo.filledWith" :readonly="!filtermode && !rightWriteEntries" multiple>
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-tab-item>

                        
                        <v-tab-item>
                            <v-container>
                                <v-row>
                                    <v-col cols="10" v-for="(source, index) in editedEntry.sources" :key="index">
                                        <v-text-field :label="$t('designation')" v-model="editedEntry.sources[index]" :readonly="!filtermode && !rightWriteEntries">
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="2" v-show="rightWriteEntries">
                                        <v-btn icon color="error" @click="removeSourceFromEditedEntry()">
                                            <v-icon>fas fa-minus</v-icon>
                                        </v-btn>
                                        <v-btn icon color="primary" @click="addEmptySourceToEditedEntry()">
                                            <v-icon>fas fa-plus</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-tab-item>

                        
                        <v-tab-item>
                            <v-container>
                                <v-row>
                                    <v-col :cols="editedEntry.images.length == 0 ? 12 : 6">
                                        <input  type="file" name="fileupload" id="fileupload" @change="onImagePreviewLoad" class="inputfile inputfile-1" multiple v-show="rightWriteEntries" accept=".jpg,.png,.jpeg,.gif,.svg" />
                                        <label for="fileupload" class="uploadImage" v-show="rightWriteEntries"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
                                                <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"></path>
                                            </svg>
                                            <span>{{ $t("uploadImage") }}</span>
                                        </label>
                                    </v-col>
                                    <v-col cols="3" class="padding-top-button" v-show="editedEntry.images.length > 0">
                                        <v-label>{{ $t("image") }}:
                                            {{ currentPreviewImage.page + 1 }}/{{
                          editedEntry.images.length
                        }}</v-label>
                                    </v-col>
                                    <v-col cols="3" v-show="editedEntry.images.length > 0">
                                        <v-btn icon color="primary" @click="previousPreviewImage()" :disabled="currentPreviewImage.page <= 0">
                                            <v-icon>fas fa-chevron-left</v-icon>
                                        </v-btn>
                                        <v-btn icon color="primary" @click="nextPreviewImage()" :disabled="currentPreviewImage.page === editedEntry.images.length - 1">
                                            <v-icon>fas fa-chevron-right</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12">
                                        <div class="preview-image" v-if="editedEntry.images.length > 0">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn icon color="error" class="top-right z-2 bg-white-a05 bg-blur" v-bind="attrs" v-on="on" @click="deleteCurrentImage()" v-show="
                                editedEntry.images.length != 0 &&
                                rightWriteEntries
                              ">
                                                        <v-icon>fas fa-trash</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{ $t("deleteImage") }}</span>
                                            </v-tooltip>

                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn icon color="primary" class="top-right-2 z-2 bg-white-a05 bg-blur" v-bind="attrs" v-on="on" @click="makeCurrentImageToEntryPreviewImage()" v-show="
                                editedEntry.images.length != 0 &&
                                rightWriteEntries
                              " :disabled="
                                editedEntry.previewImage == currentPreviewImage.name ||
                                editedEntry.previewImage ==
                                  currentPreviewImage.page - 1
                              ">
                                                        <v-icon>fas fa-images</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{ $t("markAsPreview") }}</span>
                                            </v-tooltip>

                                            <div class="w-100 h-40vh position-relative">
                                                <div class="position-absolute top-0 left-0 w-100 h-100 d-table" v-if="downloadingImage">
                                                    <div class="d-table-cell text-center v-align-center">
                                                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                                                    </div>
                                                </div>
                                                <img v-if="currentPreviewImage.url" :src="currentPreviewImage.url" @click="showImage(editedEntry, currentPreviewImage.name)" class="of-contain w-100 h-100" />
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-row>
                        <v-col cols="5" md="4" v-show="rightWriteEntries">
                            <v-btn color="secondary" text @click="closePopupOnCancel()">
                                {{ $t("cancel") }}
                            </v-btn>
                        </v-col>
                        <v-col cols="5" md="4"  v-show="!rightWriteEntries">
                            <v-btn color="secondary" text @click="createEntryPopup = false">
                                {{ $t("close") }}
                            </v-btn>
                        </v-col>
                        <v-col cols="5" md="4" v-show="createmode && !filtermode && rightWriteEntries">
                            <v-btn  color="primary" text @click="createEntry()">
                                {{ $t("save") }}
                            </v-btn>
                        </v-col>
                        <v-col cols="5" md="4" v-show="filtermode">
                            <v-btn  color="error" text @click="isFiltering = false; resetEditedEntry(true); createEntryPopup = false; fetchEntries();">
                                {{ $t("reset") }}
                            </v-btn>
                        </v-col>
                        <v-col cols="5" md="4" v-show="createmode && filtermode">
                            <v-btn  color="primary" textf @click="isFiltering = true; fetchEntries(); createEntryPopup = false;">
                                {{ $t("filter") }}
                            </v-btn>
                        </v-col>
                        <v-col cols="5" md="4" v-show="!createmode && !filtermode && rightWriteEntries">
                            <v-btn  color="primary" text @click="editEntry(false)">
                                {{ $t("save") }}
                            </v-btn>
                        </v-col>

                    </v-row>

                </v-card-actions>
            </v-card>
        </v-dialog> -->

        <!-- Confirmation Popup -->
        <v-dialog v-model="confirmation.shown" width="500">
            <v-card>
                <v-card-title>
                    {{ $t("security_question") }}
                </v-card-title>

                <v-card-text>
                    {{ confirmation.text }}
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" text @click="confirmation.shown = false">
                        {{ $t("no") }}
                    </v-btn>
                    <v-btn color="primary" text @click="
                confirmation.action();
                confirmation.shown = false;
              ">
                        {{ $t("yes") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>

    <div id="image-fullscreen-dialog" v-show="showFullscreenImage" class="position-fixed w-100 h-100 left-0 top-0 z-1000 bg-white">
        <img :src="fullscreenImage" class="w-100 h-100 of-contain" />
        <div class="position-absolute top-0 left-0 w-100 h-100 d-table" v-if="downloadingFullscreenImage">
            <div class="d-table-cell text-center v-align-center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>
        </div>
        <div class="position-absolute top-0 right-0 pr-3 pt-3">
            <v-btn rounded color="error" @click="showFullscreenImage = false">
                <span>
                    <v-icon>close</v-icon>
                </span>
            </v-btn>
        </div>
    </div>

</div>
</template>

<script>
import chevronup from '../assets/img/svg/icons/trash.svg'
import ChevronUp from './icons/chevronup.vue'

export default {

    components: {
        chevronup,
        ChevronUp,
    },
    watch: {
        detonators : function(){
            // console.log("DATA PUSHED");
            // this.pushData();
        },
        'filtEntry.group'(newVal, oldVal) {
            console.log(newVal, oldVal)
            this.showPossibles(newVal);
        }
    },

    data() {
        return {
            page: 1,
            totalPages: 1,
            ElementPerPage: 20,
            sort: null,
            order: true,
            morefilter: false,
            totalEntries:null,

            search: null,

            filterchips:null,

            // Menues
            filterSortMenu: false,
            filterFormMenu: false,
            filterGroupMenu: false,
            filterTypeMenu: false,
            filterVariationMenu:false,
            filterDetonatorMenu: false,
            filterMaterialMenu: false,
            filterPeriodMenu: false,
            filterNationMenu:false,
            filterDiameterMenu: false,
            filterTotalLengthMenu: false,
            filterLengthOfBodyMenu:false,
            filterTotalHeightMenu: false,
            filterHeightOfBodyMenu:false,
            filterWidthMenu: false,
            filterDrivingbandsMenu: false,






            selectedFinding: null,
            routes: {
                entries: "api/entries",
            },
            emailRules: [
                (v) => !!v || "E-mail is required",
                (v) =>
                /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                "E-mail must be valid",
            ],
            rules: [(value) => !!value || this.$t("required")],
            entries: [],
            weapongroups: [],
            weapontypes: [],
            weaponvariations: [],
            detonators: [],
            nations: [],
            periodsofuse: [],
            weaponforms: [],
            propellants: [],
            securityadvices: [],
            materials: [],
            explosives: [],

            possibleWeapontypesFilter: [],
            possibleWeaponvariationsFilter: [],


            // Selectable Objects

            selectableWeaponForms: [],
            selectableDetonators: [],
            selectableWeapongroups: [],
            selectableWeapontypes: [],
            selectableWeaponvariations: [],
            selectablePeriodsOfUse: [],
            selectablePropellants: [],
            selectableSecurityAdvices: [],
            selectableMaterials: [],
            selectableExplosives: [],

            // Measurements
            measurementUnitLengthSelected: false,
            measurementUnitWeightSelected: false,
            measurementLengthSuffix: "mm",
            measurementWeightSuffix: "g",

            // Images
            currentPreviewImage: {
                page: 0,
                name: "",
                url: null,
            },

            rightWriteEntries: false,
            rightPublishEntries: false,
            rightPublishOwnEntries: false,

             // Measurement Fields
            measurementFieldsDistance: [
                "diameter",
                "width",
                "totalLength",
                "totalHeight",
                "lengthOfBody",
                "heightOfBody",
            ],
            measurementFieldsWeight: ["totalWeight", "fillingWeight"],
            measurementSlider: {
                min: 0,
                max: 100000,
            },

            min: 0,
            max: 1000,
            filterLengthRange: [0,240],

            filterPeriods: [],

            filtEntry: {
                id: null,
                title: "",
                description: "",
                group: [],
                measurements: {
                    diameter: [0,0],
                    width: [0,0],
                    totalLength: [0,0],
                    totalHeight: [0,0],
                    lengthOfBody: [0,0],
                    heightOfBody: [0,0],
                    totalWeight: [0,0],
                    fillingWeight: [0,0],
                },
                additionalInfo: {
                    nation: null,
                    periodOfUse: [],
                    drivingBand1:[0,0],
                    drivingBand2:[0,0],
                    drivingBand3:[0,0],
                    drivingBands: [[0,0],[0,0],[0,0]],
                    explosiveBoosters: [],
                    propellant: null,
                    documents: [],
                    form: [],
                    securityAdvices: [],
                    materials: [],
                    filledWith: [],
                },
                sources: [""],
                images: [],
                rawImages: {},
                previewImage: null,
            },
            editedEntry: null,
            confirmation: {
                text: "",
                action: null,
                shown: false,
            },

            // Own User
            me: null,

            // Filter Mode
            filtermode: false,
            isFiltering: false,
            sortings: [
                {
                    name: "A-Z",
                    value: "title"
                },
                {
                    name: "weapongroup",
                    value: "group.name"
                },
                {
                    name: "nation",
                    value: "additionalInfo.nation"
                },
            ],
            orderings: [
                {
                    name: "ASC",
                    value: true
                },
                {
                    name: "DESC",
                    value: false

                }
            ],



            drawerDetails: {
                type: 'findings',
                filtermode: false,
                createmode: false,
                entry:null,
                imageCount: null,
            },

            metadata: null,

            deletedImages: [],

            selectedEntryPopupTab: null,

            createEntryPopup: false,
            createmode: true,

            showFullscreenImage: false,
            fullscreenImage: null,
            downloadingFullscreenImage: false,
            downloadingImage: false,
            notFoundImage: require('@/assets/img/svg/NotFound.svg'),
            blankImage: require('@/assets/img/svg/Blank.svg'),
        };
    },

    mounted() {
        // Initialize empty edited Entry
        this.resetEditedEntry();

        this.fetchMe();

        showLoadingCircle(true);

        var _this = this;
        this.fetchEntries();
        this.fetchAllDetonatorEntries();
        
        this.fetch("weapongroups", function () {
            _this.makeSelectable(_this.weapongroups, "selectableWeapongroups");
        });
        this.fetch("weapontypes", function () {
            _this.makeSelectable(_this.weapontypes, "selectableWeapontypes");
            _this.selectableWeapontypes = _this.weapontypes;
        });
        this.fetch("weaponvariations", function () {
            _this.makeSelectable(_this.weaponvariations, "selectableWeaponvariations");
            _this.selectableWeaponvariations = _this.weaponvariations;
        });
        // this.fetch("detonators", function () {
        //     _this.makeSelectable(_this.detonators, "selectableDetonators");
        // });
        this.fetch("nations", function () {

        });
        this.fetch("periodsofuse", function () {
            _this.makeSelectable(_this.periodsofuse, "selectablePeriodsOfUse");
        });
        this.fetch("weaponforms", function () {
            _this.makeSelectable(_this.weaponforms, "selectableWeaponForms");
        });
        this.fetch("propellants", function () {
            _this.makeSelectable(_this.propellants, "selectablePropellants");
        });
        this.fetch("securityadvices", function () {
            _this.makeSelectable(_this.securityadvices, "selectableSecurityAdvices");
        });
        this.fetch("materials", function () {
            _this.makeSelectable(_this.materials, "selectableMaterials");
        });
        this.fetch("explosives", function () {
            _this.makeSelectable(_this.explosives, "selectableExplosives");
        });
        
        showLoadingCircle(true);

        setTimeout(() => {
        console.log("2 seconds have passed!");
        this.pushData();
        showLoadingCircle(false);
        // Add the code you want to execute after the wait here
      }, 2000);
        // console.log(this.weapontypes);
        // console.log(this.weaponvariations);
        // console.log(this.filtEntry.group);
        // if(this.filtEntry.group.length == 0){
        //     console.log("BIN DRIN");
        //     this.possibleWeapontypesFilter = this.weapontypes;
        //     this.possibleWeaponvariationsFilter = this.weaponvariations;
        // }
        // console.log(this.possibleWeapontypesFilter);
        // console.log(this.possibleWeaponvariationsFilter);
    },

    methods: {
        showPossibles(arr){
            let arrtypefilter = [];
            let arrvariationfilter = [];
            arr.forEach(element => {
                console.log(element);
                for (let i = 0; i < element.possibleSubTypes.length; i++) {
                    const subtype = element.possibleSubTypes[i];
                    arrtypefilter.push(subtype);
                }
                for (let i = 0; i < element.possibleVariations.length; i++) {
                    const variation = element.possibleVariations[i];
                    arrvariationfilter.push(variation);
                }
            });
            this.possibleWeapontypesFilter = arrtypefilter
                .flatMap(id => this.weapontypes.find(element => element.id === id))
                .filter(element => element != null);
            this.possibleWeaponvariationsFilter = arrvariationfilter
                .flatMap(id => this.weaponvariations.find(element => element.id === id))
                .filter(element => element != null);

            console.log(this.possibleWeapontypesFilter);
            console.log(this.possibleWeaponvariationsFilter);
        },
        checkFilterChips(){
            return typeof this.filtEntry.group != 'undefined' && this.filtEntry.group.length > 0
                || typeof this.filtEntry.type != 'undefined' && this.filtEntry.type.length > 0
                || typeof this.filtEntry.variation != 'undefined' && this.filtEntry.variation.length > 0
                || typeof this.filtEntry.detonators != 'undefined' && this.filtEntry.detonators.length > 0
                || typeof this.filtEntry.additionalInfo.materials != 'undefined' && this.filtEntry.additionalInfo.materials.length > 0
                || this.filtEntry.additionalInfo.periodOfUse != null && typeof this.filtEntry.additionalInfo.periodOfUse != 'undefined' && this.filtEntry.additionalInfo.periodOfUse.length > 0
                || this.filtEntry.additionalInfo.nation != null
                || this.filtEntry.measurements.diameter[1] > 0
                || this.filtEntry.measurements.width[1] > 0
                || this.filtEntry.measurements.lengthOfBody[1] > 0
                || this.filtEntry.measurements.heightOfBody[1] > 0
                || this.filtEntry.measurements.totalLength[1] > 0
                || this.filtEntry.measurements.totalHeight[1] > 0
                || this.filtEntry.additionalInfo.drivingBand1[1] > 0
                || this.filtEntry.additionalInfo.drivingBand2[1] > 0
                || this.filtEntry.additionalInfo.drivingBand3[1] > 0;

        },
        removeFilterEntry(type,filter){
            switch (type) {
                case "all":
                    this.filtEntry = {
                        id: null,
                        title: "",
                        description: "",
                        group: [],
                        measurements: {
                            diameter: [0,0],
                            width: [0,0],
                            totalLength: [0,0],
                            totalHeight: [0,0],
                            lengthOfBody: [0,0],
                            heightOfBody: [0,0],
                            totalWeight: [0,0],
                            fillingWeight: [0,0],
                        },
                        additionalInfo: {
                            nation: null,
                            periodOfUse: null,
                            drivingBands: [],
                            drivingBand1: [0,0],
                            drivingBand2: [0,0],
                            drivingBand3: [0,0],
                            explosiveBoosters: [],
                            propellant: null,
                            documents: [],
                            form: [],
                            securityAdvices: [],
                            materials: [],
                            filledWith: [],
                        },
                        sources: [""],
                        images: [],
                        rawImages: {},
                        previewImage: null,
                        }
                    break;
                case "weapongroups":
                    const groups = this.filtEntry.group;
                    groups.splice(groups.indexOf(filter),1);
                    this.filtEntry.group = groups;
                    break;
                case "materials":
                    const materials = this.filtEntry.additionalInfo.materials;
                    materials.splice(materials.indexOf(filter),1);
                    this.filtEntry.additionalInfo.materials = materials;
                    break;
                case "periodOfUse":
                    const periodOfUse = this.filtEntry.additionalInfo.periodOfUse;
                    periodOfUse.splice(periodOfUse.indexOf(filter),1);
                    this.filtEntry.additionalInfo.periodOfUse = periodOfUse;
                    break;
                case "weapontypes":
                    const types = this.filtEntry.type;
                    types.splice(types.indexOf(filter),1);
                    this.filtEntry.type = types;
                    break;
                case "weaponvariation":
                    const variations = this.filtEntry.variation;
                    variations.splice(variations.indexOf(filter),1);
                    this.filtEntry.variations = variations;
                    break;
                case "detonators":
                    const detonators = this.filtEntry.detonators;
                    detonators.splice(detonators.indexOf(filter),1);
                    this.filtEntry.detonators = detonators;
                    break;
                case "nations":
                    this.filtEntry.additionalInfo.nation = null;
                    break;
                case "diameter":
                    this.filtEntry.measurements.diameter = [0,0];
                    break;
                case "width":
                    this.filtEntry.measurements.width = [0,0];
                    break;
                case "lengthOfBody":
                    this.filtEntry.measurements.lengthOfBody = [0,0];
                    break;
                case "heightOfBody":
                    this.filtEntry.measurements.heightOfBody = [0,0];
                    break;
                case "totalLength":
                    this.filtEntry.measurements.totalLength = [0,0];
                    break;
                case "totalHeight":
                    this.filtEntry.measurements.totalHeight = [0,0];
                    break;
                case "drivingBand1":
                    this.filtEntry.additionalInfo.drivingBand1 = [0,0];
                    break;
                case "drivingBand2":
                    this.filtEntry.additionalInfo.drivingBand2 = [0,0];
                    break;
                case "drivingBand3":
                    this.filtEntry.additionalInfo.drivingBand3 = [0,0];
                    break;

                default:
                    break;
            }

            this.searchEntry();
        },
        infiniteScrolling(entries, observer, isIntersecting) {
            if(isIntersecting){
                setTimeout(() => {
                    var _this = this;
                    var searchEntry = {};
                    searchEntry = this.convertMeasurements();
                    if(this.page  < this.totalPages){
                        this.page++;
                        searchEntry.groupIds = this.flatMapId(searchEntry.group);
                        searchEntry.typeIds = this.flatMapId(searchEntry.type);
                        searchEntry.variationIds = this.flatMapId(searchEntry.variation);
                        searchEntry.detonatorIds = this.flatMapId(searchEntry.detonators);
                        searchEntry.materialIds = this.flatMapId(
                            searchEntry.additionalInfo.materials
                        );
                        searchEntry.periodIds = this.flatMapId(
                            searchEntry.additionalInfo.periodOfUse
                        )
                        searchEntry.securityAdviceIds = this.flatMapId(
                            searchEntry.additionalInfo.securityAdvices
                        );
                        searchEntry.filledWithIds = this.flatMapId(
                            searchEntry.additionalInfo.filledWith
                        );
                        searchEntry.nation = searchEntry.additionalInfo.nation;

                        searchEntry.additionalInfo = null;

                        searchEntry.isPublic = false;



                        // Apply Sort and Order variables
                        var orderColumn = null;
                        switch (this.sort) {
                            case "title":
                                orderColumn = "title";
                                break;
                            case "group.name":
                                orderColumn = "group";
                                break;
                            case "additionalInfo.nation":
                                orderColumn = "nation";
                                break;
                            default:
                                orderColumn = "title";
                        }
                        searchEntry.orderColumn = orderColumn;
                        searchEntry.orderDirection = this.order ? "ASC" : "DESC";
                        searchEntry.page = this.page - 1;
                        searchEntry.size = this.ElementPerPage;

                        this.$axios
                            .post(this.routes.entries + "/search",searchEntry)
                            .then(function (response) {
                                if (response.data.content != null) {
                                    if(response.data.content.length > 1) {
                                        response.data.content.forEach(item => _this.entries.push(item));
                                    }
                                } else {
                                    if(response.data.length > 1) {
                                        response.data.forEach(item => _this.entries.push(item));
                                    }
                                }
                            })
                            .catch(this.onError);
                    }
                }, 500);
            }
        },
        drawerClosed(){
            this.selectedFinding = null;
        },
        openDrawer(entry,editmode,createmode){
            entry = this.copy(entry);
            if(entry.images != null && entry.images != 'undefined' ){
                this.drawerDetails.imageCount = entry.images.length;
            }
            this.drawerDetails.entry = entry;
            if(!createmode){
                this.drawerDetails.entry.additionalInfo.drivingBand1 = this.drawerDetails.entry.additionalInfo.drivingBand1 == null ? '0' : this.drawerDetails.entry.additionalInfo.drivingBand1;
                this.drawerDetails.entry.additionalInfo.drivingBand2 = this.drawerDetails.entry.additionalInfo.drivingBand2 == null ? '0' : this.drawerDetails.entry.additionalInfo.drivingBand2;
                this.drawerDetails.entry.additionalInfo.drivingBand3 = this.drawerDetails.entry.additionalInfo.drivingBand3 == null ? '0': this.drawerDetails.entry.additionalInfo.drivingBand3;
            }

            if(!createmode){
                if(this.drawerDetails.entry.images != null && this.drawerDetails.entry.images.length > 0){
                    this.drawerDetails.entry.images = this.getAllImages(entry,entry.images);
                }else {
                    this.drawerDetails.entry.images = entry.images;
                    
                }
                this.drawerDetails.entry.nation = this.setCurrentNation(entry);
            }
            this.drawerDetails.rightWriteEntries = this.rightWriteEntries;
            this.drawerDetails.rightPublishEntries = this.rightPublishEntries;
            this.drawerDetails.rightPublishOwnEntries = this.rightPublishOwnEntries;
            this.drawerDetails.editmode = editmode;
            this.drawerDetails.createmode = createmode;

            this.$emit('showDetails',this.drawerDetails);
        },
        pushData(){

            const metadata = {};
            metadata.selectableWeapontypes = this.selectableWeapontypes;
            metadata.selectableWeaponvariations = this.selectableWeaponvariations;
            metadata.weapongroups = this.weapongroups;
            metadata.weapontypes = this.weapontypes;
            metadata.weaponvariations = this.weaponvariations;
            metadata.detonators = this.detonators;
            metadata.nations = this.nations;
            metadata.periodsofuse = this.periodsofuse;
            metadata.weaponforms = this.weaponforms;
            metadata.propellants = this.propellants;
            metadata.securityadvices = this.securityadvices;
            metadata.materials = this.materials;
            metadata.explosives = this.explosives;
            this.metadata = metadata;
            console.log(metadata);

            console.log('Data emitted:', this.metadata)
            this.$emit('pullData',this.metadata);
        },
        onError(err) {
            if (err.response != null && err.response.data != null) {
                this.$noty.error('<i class="fas fa-minus-circle mr-3"></i>' + this.$t(err.response.data.message));
            } else {
                console.error(err);
            }
        },
        fetchMe() {
            var _this = this;
            this.$axios
                .get("api/me")
                .then(function (response) {
                    _this.me = response.data;
                    _this.rightWriteEntries = _this.hasRight("WriteEntries");
                    _this.rightPublishEntries = _this.hasRight("PublishEntries");
                    _this.rightPublishOwnEntries = _this.hasRight("PublishOwnEntries");
                })
                .catch(this.onError);
        },
        fetchEntries() {
            this.fetchAllDetonatorEntries()
            if (this.isFiltering) {
                this.filterEntry();
            } else {
                var _this = this;
                this.$axios
                    .get("api/entries", {
                        params: {
                            page: _this.page - 1,
                            size: _this.ElementPerPage,
                            order: _this.order ? "ASC" : "DESC",
                            sort: _this.sort,
                            includeNonPublished: true
                        },
                    })
                    .then(_this.gotEntries)
                    .catch(_this.onError);
            }

        },
        fetchAllDetonatorEntries() {
            console.log("DETONATORS REFRESHED");
            var _this = this;
                this.$axios
                    .get("api/entries/detonators")
                    .then(_this.gotDetonatorEntries)
                    .catch(_this.onError);
            
        },
        gotDetonatorEntries(response){
            const deto = [];
            for (let i = 0; i < response.data.content.length; i++) {
                const element = response.data.content[i];
                // console.log(element)
                // console.log(element.entryType)
                // console.log(element.entryType == "Default")
                if(element.entryType == "Detonator"){
                    deto.push(element)
                }
            }
            this.detonators = deto;
            console.log("DETOnator");
            // console.log(deto);
            console.log(this.detonators);
        },
        gotEntries(response) {
            console
            this.totalEntries = response.data.totalElements;
            this.totalPages = response.data.totalPages;
            this.entries = response.data.content;
        },
        getPreviewImage(entry,image){
            if(image == null){
                    return '';
            }

            if(image.url != null){
                return image.url;
            }else{
                try {
                    JSON.parse(image);
                } catch (e) {
                    if(typeof image == "string" && (image.endsWith(".jpg") || image.endsWith(".png") || image.endsWith(".gif") || image.endsWith(".jpeg"))){
                        return this.gotImage(entry, image);
                    }
                    return '';
                }
                var img = JSON.parse(image);
                var obj = this.gotImage(entry,img.image);
                return obj;
            }
        },
        fetch(obj, afterFetch = null) {
            var _this = this;
            var route = "api/" + obj;
            if (obj === "entries") {
                this.$axios
                    .get(route, {
                        params: {
                            page: this.page - 1,
                            size: this.ElementPerPage,
                        },
                    })
                    .then(function (response) {
                        _this.totalPages = response.data.totalPages;
                        _this.entries = response.data.content;
                        if (afterFetch != null) afterFetch();
                    })
                    .catch(this.onError);
            } else {
                this.$axios
                    .get(route + "/all")
                    .then(function (response) {
                        _this[obj] = response.data;
                        if (afterFetch != null) afterFetch();
                    })
                    .catch(this.onError);
            }
        },
        makeSelectable(obj, selectableName) {
            this[selectableName] = [];
            if (obj == null) return;
            for (let i = 0; i < obj.length; i++) {
                const el = obj[i];

                this[selectableName].push({
                    value: el,
                    text: this.$t(el.name != null ? el.name : el),
                });
            }
        },
        /**
         * This creates the selectable Array over a given Id-Array which is being used for searching inside baseObjectArray.
         * The param obj shall be the Id-Array.
         */
        makeSelectableWithIds(obj, selectableName, baseObjectArray) {
            var objArray = [];
            if (obj == null) return;
            if (baseObjectArray == null) return;
            for (let i = 0; i < obj.length; i++) {
                const id = obj[i];
                const el = this.findByParam(baseObjectArray, id, "id");
                if (el == null) continue;
                objArray.push(el);
            }

            this.makeSelectable(objArray, selectableName);
        },
        unwrapName(obj) {
            if (obj != null) {
                return obj.name;
            }
            return "";
        },
        extractNation(id){
            const nation = this.nations.find(n => n.id == id);
            if(id != null && nation != null){
                return nation.name; 
            }
            return ""
        },
        // OnChange Methods

        weaponGroupChanged() {
            if (this.filtermode) {
                return;
            }

            if (this.editedEntry.group == null) {
                this.selectableWeapontypes = this.weapontypes;
            } else {
                const group = this.editedEntry.group;
                this.selectableWeapontypes = group.possibleSubTypes
                    .flatMap(id => this.weapontypes.find(element => element.id === id))
                    .filter(element => element != null);

                if (!this.selectableWeapontypes.includes(this.editedEntry.type)) {
                    this.editedEntry.type = null;
                }
            }

            this.weaponTypeChanged();
        },

        weaponTypeChanged() {
            if (this.filtermode) {
                return;
            }

            if (this.editedEntry.type == null) {
                if (this.selectableWeapontypes.find(element => element.possibleSubTypes.length === 0)) {
                    this.selectableWeaponvariations = this.weaponvariations;
                } else {
                    this.selectableWeaponvariations = this.weaponvariations.filter(variation => this.selectableWeapontypes.find(type => type.possibleSubTypes.includes(variation.id)));
                }
            } else {
                const type = this.editedEntry.type;
                this.selectableWeaponvariations = type.possibleSubTypes
                    .flatMap(id => this.weaponvariations.find(element => element.id === id))
                    .filter(element => element != null);
                if (!this.selectableWeaponvariations.includes(this.editedEntry.variation)) {
                    this.editedEntry.variation = null;
                }
            }
        },

        weapongroupSelected() {
            if (this.filtermode) return;
            if (this.editedEntry == null) return;

            // Reset if nothing selected
            if (this.editedEntry.group == null) {
                this.makeSelectable(this.weapontypes, "selectableWeapontypes");
                this.makeSelectable(
                    this.weaponvariations,
                    "selectableWeaponvariations"
                );
                return;
            }

            // only allowed sub types allowed
            this.makeSelectableWithIds(
                this.editedEntry.group.possibleSubTypes,
                "selectableWeapontypes",
                this.weapontypes
            );

            var allVariations = [];
            if (this.editedEntry.group.possibleSubTypes == null) return;
            for (let i = 0; i < this.editedEntry.group.possibleSubTypes.length; i++) {
                const typeId = this.editedEntry.group.possibleSubTypes[i];
                const type = this.findByParam(this.weapontypes, typeId, "id");
                for (let p = 0; p < type.possibleSubTypes.length; p++) {
                    var variation = this.findByParam(
                        this.weaponvariations,
                        type.possibleSubTypes[p],
                        "id"
                    );
                    if (variation != null) allVariations.push(variation);
                }
            }
            // only allowed variations from all allowed types
            this.makeSelectable(allVariations, "selectableWeaponvariations");
        },
        weapontypeSelected() {
            if (this.filtermode) return;
            if (this.editedEntry.type == null) {
                this.makeSelectable(
                    this.weaponvariations,
                    "selectableWeaponvariations"
                );
                return;
            }

            // only allowed sub types allowed
            this.makeSelectableWithIds(
                this.editedEntry.type.possibleSubTypes,
                "selectableWeaponvariations",
                this.weaponvariations
            );
        },

        // Create and Update Methods
        createEntry() {
            var saveEntry = this.convertEntryToRequest(this.editedEntry);

            var _this = this;
            this.$axios
                .post(this.routes.entries, saveEntry)
                .then(function (response) {
                    _this.$noty.success('<i class="fas fa-check-circle mr-3"></i>' + _this.$t("entry_created"));

                    const entry = response.data;

                    _this.uploadImages(entry.id, _this.editedEntry.images).then(function () {
                        _this.resetEditedEntry();
                        _this.createEntryPopup = false;
                        _this.fetchEntries();
                    });
                })
                .catch(this.onError);
        },
        uploadImages(entryId, images) {
            const actualImages = images.filter(element => typeof (element) !== "string");

            var promises = [];

            if (actualImages.length > 0) {
                actualImages.forEach(element => {
                    promises.push(this.uploadSingleImage(entryId, element));
                });
            }

            return Promise.all(promises);
        },
        uploadSingleImage(entryId, file) {
            var data = new FormData();
            data.append("file", file, file.fileName);
            return this.$axios
                .post(this.routes.entries + "/" + entryId + "/images", data, {
                    headers: {
                        accept: "application/json",
                        "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
                    },
                });
        },
        convertMeasurements(){
            this.filtEntry.measurementsFrom = this.copy(this.editedEntry.measurements);
            this.filtEntry.measurementsTo = this.copy(this.editedEntry.measurements);
            var isFilled = false;

            var MeasureEntry = {};
            Object.assign(MeasureEntry, this.filtEntry);


            for (var prop in MeasureEntry.measurements) {
                if(MeasureEntry.measurements[prop][1] > 0){
                    MeasureEntry.measurementsFrom[prop] = MeasureEntry.measurements[prop][0];
                    MeasureEntry.measurementsTo[prop] = MeasureEntry.measurements[prop][1];
                    isFilled = true;
                }else{
                    MeasureEntry.measurementsFrom[prop] = 0;
                    MeasureEntry.measurementsTo[prop] = 0;
                }
            }

            MeasureEntry.measurements = null;

            return MeasureEntry;
        },
        convertDrivingBandForFilter(band,startinc = true,endinc = true){
            const drivingband = {};
            drivingband.start = band[0];
            drivingband.end = band[1];
            drivingband.startInclusive = startinc;
            drivingband.endInclusive = endinc;
            return drivingband;
        },
        searchEntry(){
            if(this.search != null && this.search != '' || this.checkFilterChips()){
                this.filterEntry();
            }else {
                this.fetchEntries();
            }
        },
        filterEntry() {
            var _this = this;
            var searchEntry = {};
            if(this.filtermode){
                Object.assign(searchEntry, this.editedEntry);
                var measurementsFilled = false;
                for (var prop in searchEntry.measurements) {
                    if (searchEntry.measurements.hasOwnProperty(prop)) {
                        if (searchEntry.measurements[prop] > 0) {
                            measurementsFilled = true;
                        } else {
                            searchEntry.measurements[prop] = null;
                        }
                    }
                }
                if (!measurementsFilled) searchEntry.measurements = null;
            }else{
               searchEntry = this.convertMeasurements();
            }

            searchEntry.title = this.search != null ? this.search : null; 

            searchEntry.groupIds = this.flatMapId(searchEntry.group);
            searchEntry.typeIds = this.flatMapId(searchEntry.type);
            searchEntry.variationIds = this.flatMapId(searchEntry.variation);
            searchEntry.detonatorIds = this.flatMapId(searchEntry.detonators);
            searchEntry.materialIds = this.flatMapId(searchEntry.additionalInfo.materials);
            searchEntry.securityAdviceIds = this.flatMapId(searchEntry.additionalInfo.securityAdvices);
            searchEntry.filledWithIds = this.flatMapId(searchEntry.additionalInfo.filledWith);
            searchEntry.nation = searchEntry.additionalInfo.nation;
            searchEntry.drivingBand1 = this.convertDrivingBandForFilter(searchEntry.additionalInfo.drivingBand1),
            searchEntry.drivingBand2 = this.convertDrivingBandForFilter(searchEntry.additionalInfo.drivingBand2),
            searchEntry.drivingBand3 = this.convertDrivingBandForFilter(searchEntry.additionalInfo.drivingBand3),
            searchEntry.periodIds = this.flatMapId(searchEntry.additionalInfo.periodOfUse);
            searchEntry.additionalInfo = null;

            searchEntry.isPublic = false;



            // Apply Sort and Order variables
            var orderColumn = null;
            switch (this.sort) {
                case "title":
                    orderColumn = "title";
                    break;
                case "group.name":
                    orderColumn = "group";
                    break;
                case "additionalInfo.nation":
                    orderColumn = "nation";
                    break;
                default:
                    orderColumn = "title";
            }
            searchEntry.orderColumn = orderColumn;
            searchEntry.orderDirection = this.order ? "ASC" : "DESC";
            searchEntry.page = this.page - 1;
            searchEntry.size = this.ElementPerPage;

            this.$axios
                .post(this.routes.entries + "/search",searchEntry)
                .then(function (response) {
                    _this.totalPages = response.data.totalPages;
                    _this.page = 1;
                    if (response.data.content != null) {
                        _this.entries = response.data.content;
                        _this.totalEntries = response.data.totalElements;
                    } else {
                        _this.entries = response.data;
                    }
                })
                .catch(this.onError);
        },
        addImage(entry_id, file, previewImage = false) {
            if (typeof file == "string") return;
            var data = new FormData();
            data.append("file", file, file.fileName);
            data.append("preview", previewImage);
            var _this = this;
            this.$axios
                .post(this.routes.entries + "/" + entry_id + "/images", data, {
                    headers: {
                        accept: "application/json",
                        "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
                    },
                })
                .then(function (response) {
                    if (previewImage) {
                        for (let i = 0; i < _this.entries.length; i++) {
                            const entry = _this.entries[i];
                            if (entry.id == entry_id) {
                                _this.entries[i].previewImage = response.data;
                                break;
                            }
                        }
                    }
                })
                .catch(this.onError);
        },
        editEntry(publish = false, revokePublish = false) {
            var _this = this;

            var saveEntry = this.convertEntryToRequest(this.editedEntry);

            if (publish) {
                saveEntry.public = true;
            }
            if (revokePublish) {
                saveEntry.public = false;
            }

            this.$axios
                .put(this.routes.entries, saveEntry)
                .then(function (response) {
                    _this.$noty.success('<i class="fas fa-check-circle mr-3"></i>' + _this.$t("entry_edited"));

                    const entry = response.data;

                    _this.uploadImages(entry.id, _this.editedEntry.images).then(function () {
                            _this.resetEditedEntry();
                            _this.createEntryPopup = false;
                            _this.fetchEntries();
                    });
                    _this.deleteImages(entry.id, _this.deletedImages);
                    // if (!(_this.editedEntry.previewImage == null)){
                    //     _this.updatePreviewImage(entry.id, _this.editedEntry.previewImage);
                    //     console.log("_this.editedEntry.previewImage:" + _this.editedEntry.previewImage)
                    // }



                })
                .catch(this.onError);
        },
        updatePreviewImage(entryid, prevImage) {
            var _this = this;

            this.$axios
                .patch("api/entries/" + entryid + "/images/preview", {image:prevImage})
                .catch(this.onError);

        },
        deleteImages(entryid, deletedImages ,functionToCall = null) {
            var _this = this;

            for (let i = 0; i < deletedImages.length; i++) {
                this.$axios
                    .delete("api/entries/" + entryid + "/images/" + deletedImages[i])
                    .catch(err => {
                        console.error(err);
                    });
            }

        },
        deleteEntry(entryid) {
            var _this = this;
            this.$axios
                .delete("api/entries/" + entryid)
                .then(function (response) {
                    _this.$noty.success('<i class="fas fa-check-circle mr-3"></i>' + _this.$t("entry_deleted"));
                    _this.fetchEntries();
                })
                .catch(this.onError);
        },
        updatePage() {
            this.fetch("entries");
        },
        previousPage() {
            this.page = this.page - 1;
            this.fetchEntries();
        },
        nextPage() {
            this.page = this.page + 1;
            this.fetchEntries();
        },
        optionalObjectToId(obj) {
            return obj == null ? 0 : obj.id;
        },
        convertEntryToRequest(entry) {
            const result = {
                id: entry.id,
                title: entry.title,
                description: entry.description,
                groupId: this.optionalObjectToId(entry.group),
                typeId: this.optionalObjectToId(entry.type),
                variationId: entry.variation == null ? 0 : entry.variation.id,
                detonatorIds: this.flatMapId(entry.detonators),
                additionalInfo: {
                    formId: this.optionalObjectToId(entry.additionalInfo.form),
                    propellantId: this.optionalObjectToId(entry.additionalInfo.propellant),
                    nation: entry.additionalInfo.nation,
                    materialIds: this.flatMapId(entry.additionalInfo.materials),
                    securityAdviceIds: this.flatMapId(entry.additionalInfo.securityAdvices),
                    periodOfUseId: this.optionalObjectToId(entry.additionalInfo.periodOfUse),
                    filledWithIds: this.flatMapId(entry.additionalInfo.filledWith),
                    explosiveBoosters: entry.explosiveBoosters,
                    drivingBands: entry.drivingBands,
                    documentIds: this.flatMapId(entry.additionalInfo.documents)
                },
                measurements: entry.measurements,
                sources: entry.sources,
                images: entry.images,
                previewImage: entry.previewImage,
            };

            return result;
        },
        applySaveEntryData(saveEntry) {
            saveEntry.groupId = saveEntry.group != null ? saveEntry.group.id : null;
            saveEntry.typeId = saveEntry.type != null ? saveEntry.type.id : null;
            saveEntry.variationId =
                saveEntry.variation != null ? saveEntry.variation.id : null;
            saveEntry.detonatorIds = this.flatMapId(saveEntry.detonators);

            // AdditionalInfo
            saveEntry.additionalInfo = {};
            saveEntry.additionalInfo.formId =
                this.editedEntry.additionalInfo.form != null ?
                this.editedEntry.additionalInfo.form.id :
                null;
            saveEntry.additionalInfo.propellantId =
                this.editedEntry.additionalInfo.propellant != null ?
                this.editedEntry.additionalInfo.propellant.id :
                null;
            saveEntry.additionalInfo.nation = this.editedEntry.additionalInfo.nation;
            saveEntry.additionalInfo.periodOfUseId =
                this.editedEntry.additionalInfo.periodOfUse != null ?
                this.editedEntry.additionalInfo.periodOfUse.id :
                null;
            saveEntry.additionalInfo.materialIds = this.flatMapId(
                this.editedEntry.additionalInfo.materials
            );
            saveEntry.securityAdviceIds = this.flatMapId(
                saveEntry.additionalInfo.securityAdvices
            );
            saveEntry.additionalInfo.filledWithIds = this.flatMapId(
                this.editedEntry.additionalInfo.filledWith
            );
            return saveEntry;
        },
        resetEditedEntry(filter = false) {
            // Filter nicht zurücksetzen nach schließen und erneutem Öffnen
            this.editedEntry = {
                id: null,
                title: "",
                description: "",
                entryType: "Default",
                measurements: {
                    diameter: 0,
                    width: 0,
                    totalLength: 0,
                    totalHeight: 0,
                    lengthOfBody: 0,
                    heightOfBody: 0,
                    totalWeight: 0,
                    fillingWeight: 0,
                },
                additionalInfo: {
                    nation: null,
                    periodOfUse: null,
                    drivingBands: [],
                    explosiveBoosters: [],
                    propellant: null,
                    documents: [],
                    form: null,
                    securityAdvices: [],
                    materials: [],
                    filledWith: [],
                },
                sources: [""],
                images: [],
                rawImages: {},
                previewImage: null,
            };
            this.deletedImages = [];
            this.currentPreviewImage.page = 0;
            this.currentPreviewImage.url = this.blankImage;

            if (filter) {
                this.editedEntry.additionalInfo.nation = null;
            }

            // For Filter
            this.editedEntry.measurementsFrom = this.copy(this.editedEntry.measurements);
            this.editedEntry.measurementsTo = this.copy(this.editedEntry.measurements);

            // this.weaponGroupChanged();
        },
        getEditedEntry(entry) {
            this.editedEntry = this.copy(entry);
            this.setCurrentNation();
            this.editedEntry.rawImages = {};
            this.currentPreviewImage.page = 0;
            this.currentPreviewImage.url = this.blankImage;
            this.currentPreviewImage.name = null;
            if (entry.previewImage != null) {
                this.currentPreviewImage.page = entry.images.indexOf(entry.previewImage);
                // this.getImage(entry, entry.previewImage);
            } else {
                // this.setCurrentPreviewImage();
            }
            // this.weaponGroupChanged();
        },

        // Helper Methods
        changeUnit(unitName) {
            if (unitName == "length") {
                var oldUnit = this.measurementLengthSuffix;
                this.measurementLengthSuffix = this.measurementUnitLengthSelected ?
                    "cm" :
                    "mm";
                this.calculateUnitChange(
                    unitName,
                    oldUnit,
                    this.measurementLengthSuffix
                );
                return;
            }
            var oldUnit = this.measurementWeightSuffix;
            this.measurementWeightSuffix = this.measurementUnitWeightSelected ?
                "kg" :
                "g";
            this.calculateUnitChange(unitName, oldUnit, this.measurementWeightSuffix);
        },

        calculateUnitChange(unitName, oldUnit, newUnit) {
            if (this.editedEntry == null) return;
            const calc = {
                step: unitName == "length" ? 10 : 1000,
                type: oldUnit == "mm" || oldUnit == "g" ? "/" : "*",
            };
            for (var prop in this.editedEntry.measurements) {
                if (
                    Object.prototype.hasOwnProperty.call(
                        this.editedEntry.measurements,
                        prop
                    )
                ) {
                    if (
                        (prop.includes("Weight") && unitName == "length") ||
                        (!prop.includes("Weight") && unitName != "length")
                    )
                        continue;
                    if (calc.type == "/") {
                        this.editedEntry.measurements[prop] =
                            this.editedEntry.measurements[prop] / calc.step;
                    } else {
                        this.editedEntry.measurements[prop] =
                            this.editedEntry.measurements[prop] * calc.step;
                    }
                }
            }
        },

        onKeyDownNumberField(measurementValue) {
            if (this.editedEntry == null) return;
            if (
                (this.editedEntry.measurements[measurementValue] + "").includes(",")
            ) {
                this.editedEntry.measurements[
                    measurementValue
                ] = this.editedEntry.measurements[measurementValue].replace(",", ".");
            }
        },

        addEmptySourceToEditedEntry() {
            this.editedEntry.sources.push("");
        },

        removeSourceFromEditedEntry() {
            if (this.editedEntry.sources.length <= 1) return;
            this.editedEntry.sources.splice(this.editedEntry.sources.length - 1, 1);
        },

        // ImageUpload
        onImagePreviewLoad(e) {
            if (e.target.files.length == 0) return;
            for (let i = 0; i < e.target.files.length; i++) {
                const file = e.target.files[i];
                this.editedEntry.images.push(file);
            }
            // Show last uploaded image
            this.currentPreviewImage.url = URL.createObjectURL(
                e.target.files[e.target.files.length - 1]
            );
            this.currentPreviewImage.page = this.editedEntry.images.length - 1;
        },

        /**
         * This method is intended for setting the image of the current Preview Page. This shall only be called by previous and nextPreviewImage Methods
         */
        setCurrentPreviewImage() {
            if (this.editedEntry.images.length == 0) {
                this.currentPreviewImage.url = this.blankImage;
                this.currentPreviewImage.name = "";
                return;
            }
            const element = this.editedEntry.images[this.currentPreviewImage.page];
            if (typeof element == "object") {
                // Show local preview Image
                this.currentPreviewImage.url = URL.createObjectURL(element);
                this.currentPreviewImage.name = URL.createObjectURL(element);
            } else {
                // Show Image from Server
                // this.getImage(this.editedEntry, element);
            }
        },
        getAllImages(entry, images){
            const arrimg = [];
            if(images[0].url != null){
                return images;
            }
            for (let i = 0; i < images.length; i++) {
                const image = images[i];
                    this.retrieveImage(entry, image).then(function (result) {
                            // self.downloadingImage = false;
                            const obj = {name:image, url:result}
                            arrimg.push(obj);
                            // self.currentPreviewImage.url = result;
                        }).catch(this.imageNotFound);
            }
            return arrimg;
        },
        getImage(entry, image) {
            if (entry.images != null && entry.images[image] == null) {
                this.currentPreviewImage.url = this.blankImage;
                this.currentPreviewImage.name = image;
                this.downloadingImage = true;
                const self = this;

                this.retrieveImage(entry, image).then(function (result) {
                    self.downloadingImage = false;
                    self.entry.images[image] = result;
                    self.currentPreviewImage.url = result;
                }).catch(this.imageNotFound);
            } else if(entry.images != null){
                this.currentPreviewImage.url = entry.images[image];
            }
        },
        gotImage(entry, image) {
            if(image == null){
                return '';
            }
            const self = this;

            this.retrieveImage(entry,image).then(function (result) {
                            entry.previewImage = {name:image ,url:result};
                            self.currentPreviewImage.url = entry.previewImage.url;
                        }).catch(this.imageNotFound);
        },

        imageNotFound() {
            this.downloadingImage = false;
            this.currentPreviewImage.url = this.notFoundImage;
        },
        toastError(error) {
            error.response.data.text().then(text => this.toastErrorReason(text));
        },
        toastErrorReason(text) {
            //Generic function to log translated error:
            this.$noty.error('<i class="fas fa-minus-circle mr-3"></i>' + this.$t(text));
        },
        retrieveImage(entry, image) {
            const url = this.routes.entries + "/" + entry.id + "/images/" + image;
            return new Promise((resolve, reject) => {
                this.$axios.get(url, {
                    responseType: 'blob'
                }).then(function (response) {
                    const reader = new window.FileReader();
                    reader.readAsDataURL(response.data);
                    reader.onload = function () {
                        resolve(reader.result);
                    }
                    reader.onerror = reject;
                }).catch(function (error) {
                    reject(error);
                });
            });
        },

        showPreviewImage(entry) {
            if (entry.previewImage == null) {
                const first = entry.images[0];
                this.showImage(entry, first);
            } else {
                this.showImage(entry, entry.previewImage);
            }
        },
        showImage(entry, image) {
            this.fullscreenImage = null;
            this.downloadingFullscreenImage = true;
            this.showFullscreenImage = true;
            this.retrieveImage(entry, image).then(this.gotFullscreenImage).catch(this.fullscreenImageNotFound);
        },
        gotFullscreenImage(result) {
            this.downloadingFullscreenImage = false;
            this.fullscreenImage = result;
        },
        fullscreenImageNotFound(error) {
            this.downloadingFullscreenImage = false;
            this.fullscreenImage = this.notFoundImage;
        },

        deleteCurrentImage() {
            this.deletedImages.push(this.editedEntry.images[this.currentPreviewImage.page]);
            this.editedEntry.images.splice(this.currentPreviewImage.page, 1);
            // Bild löschen, wenn bereits auf Server gespeichert
            // if (typeof this.currentPreviewImage.url != "object") {

            //     this.$axios
            //         .delete(this.currentPreviewImage.url)
            //         .then(function (response) {})
            //         .catch(this.onError);
            // }



            this.currentPreviewImage.page = 0;
            this.currentPreviewImage.url = this.blankImage;
            this.previousPreviewImage();
        },

        makeCurrentImageToEntryPreviewImage() {
            // Set preview image to image index if not already uploaded
            if (this.editedEntry.id == null || this.editedEntry.id == 0) {
                this.editedEntry.previewImage = this.currentPreviewImage.page - 1;
                return;
            }
            this.editedEntry.previewImage = this.currentPreviewImage.name;
        },

        hasRight(right) {
            if (this.me.rights.includes(right)) return true;
            if (this.me.role != null) {
                return this.me.role.rights.includes(right);
            }
            return false;
        },

        flatMapId(attributes) {
            var arr = [];
            if (attributes == null || attributes.length == 0) return arr;
            attributes.forEach((attr) => {
                arr.push(attr.id);
            });
            return arr;
        },

        findByParam(array, paramValue, param = "id") {
            for (let p = 0; p < array.length; p++) {
                const el = array[p];
                if (el[param] == paramValue) {
                    return el;
                }
            }
            return null;
        },

        formatDate(dateString) {
            if (dateString == null || dateString.length == 0) return;
            var date = new Date(dateString);
            var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
            var month = date.getMonth() < 10 ? "0" + date.getMonth() : date.getMonth();
            var year = date.getFullYear();
            var hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
            var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
            var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

            return day + "." + month + "." + year + " " + hour + ":" + minutes + " " + this.$t("h");
        },

        showConfirmation(text, action) {
            this.confirmation.text = text;
            this.confirmation.action = action;
            this.confirmation.shown = true;
        },

        getEntryPopupTabs() {
            if (this.filtermode) {
                return ["general", "measurements", "additionalInfo"];
            }
            return ["general", "measurements", "additionalInfo", "sources", "images"];
        },

        setCurrentNation(entry = this.editedEntry){

            for (let i = 0; i < this.nations.length; i++) {
                const selectableNation = this.nations[i];
                if(selectableNation.id === entry.additionalInfo.nation){
                    entry.additionalInfo.nation = selectableNation.id;
                    return;
                }
            }
            this.editedEntry.additionalInfo.nation = null;
        },

        closePopupOnCancel(){
            this.createEntryPopup = false;
            if(!this.filtermode){
                this.resetEditedEntry(true);
            }
        }
    },
};
</script>
