<template>
  <div id="app">
    <v-app>
      <v-app-bar v-if="user != null" class="pb-4 px-6 px-lg-14 appbar">
        <div class="mx-0 row justify-content-between">
            <div class="pl-0 col-2 applogocontainer">
              <img class="applogo justify-content-start" src="../assets/img/svg/muniman-Logo-black.png" alt="">
            </div>
            <div class="pb-0 col-9 mx-0 row justify-content-center align-items-end">
              <v-btn class="appbarbtn" v-if="user != null" :ripple="false" @click="navObject = null" text v-for="route in allowedRoutes" :key="route.name" :to="route.to">{{ $t(route.key) }}</v-btn>
              <!-- <v-btn class="appbarbtn" :ripple="false" text >Stammdaten</v-btn>
              <v-btn class="appbarbtn" :ripple="false" text >Benutzer</v-btn>
              <v-btn class="appbarbtn" :ripple="false" text >Rollen</v-btn>
              <v-btn class="appbarbtn" :ripple="false" text >Mandanten</v-btn> -->
            </div>
            <div class="col-1 pb-0 row justify-content-end align-items-start align-items-sm-end">
              <v-menu
              v-model="logoutMenu"
              offset-y
              left
              flat>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn 
                  class="mt-2 mt-sm-0"
                  v-bind="attrs"
                  v-on="on"
                  :ripple="false"
                  text 
                  fab 
                  small> 
                    <img width='100%' height='auto' src="../assets/img/svg/icons/user.svg" style="width: 24px;">
                  </v-btn>
                </template>
                <v-card flat class="filtercard rounded-0" color="#161616">
                  <v-list class="pa-0 transparent" >
                    <v-list-item>
                      <v-list-item-title class="white--text">
                        {{user.name == "" ? user.personalData.firstname + " " + user.personalData.lastname : user.name}}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item class="white--text" @click="showingLanguageSelector = true;">
                      {{ $t("select_language") }}
                    </v-list-item>
                    <v-list-item @click="logout()">
                      <v-list-item-title class="white--text">
                        Logout
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </div>
          
        </div>
      </v-app-bar>
      <v-navigation-drawer
      v-model="drawer"
      stateless
      fixed
      right
      v-bind:class="{'findings-drawer' : navObject.type == 'findings','users-drawer' : navObject.type == 'users'}"
      class="w-md-75 w-lg-50 w-xs-100"
      v-if="checkDrawer()"
      >
      <div v-if="navObject.type == 'findings'">
        <findings-form :reload="fetchEntries" :close="close" :nav-object="navObject" :edit-entry="editEntry" :metadata="metadata" :get-entry-popup-tabs="getEntryPopupTabs" />
      </div>
      <div v-if="navObject.type == 'users'">
        <users-form :removeDevice="removeDevice" :reload="fetchUsers" :close="close" :nav-object="navObject" :edit-user="editUser" :metadata="metadata" />
      </div>
      </v-navigation-drawer> 

      <!-- Navigation -->

      <v-main class="main">
        <router-view  ref="findings" @pullUserData="pullUserData" @pullData="pullData" @showUserDetails="showUserDetails" @showDetails="showDetails" @updateState="updateState">
        </router-view>
      </v-main>

      <LanguageSelector v-model="showingLanguageSelector" />

    </v-app>
  </div>
</template>

<script>
// import FindingsForm from '.../BaseData.vue'
import UsersForm from '../components/custom/UsersForm.vue'
import FindingsForm from '../components/custom/FindingsForm.vue'
import LanguageSelector from "@/components/custom/LanguageSelector.vue";

export default {
  name: "app",
  props: {

  },

  components: {
    LanguageSelector,
        FindingsForm,
        UsersForm,
    },

  data() {
    return {

      drawer:null,
      logoURL: 'C:\SpringWorkspace\sensys-muniman\BackendServer\src\main\resources\public\static\img\logo.svg',
      navObject: null,
      editEntry:null,
      editUser:null,
      logoutMenu: false,

      metadata:null,
      selectedEntryPopupTab: null,

      showingLanguageSelector: false,

      standardRoutes: [
        {
          key: "objects",
          icon: "fas fa-bomb",
          to: "/findings",
        },
        {
          key: "masterdata",
          icon: "fas fa-database",
          to: "/masterdata",
          right: "WriteMasterdata",
        },
        {
          key: "users",
          icon: "fas fa-user",
          to: "/users",
          right: "ReadUsers",
        },
        {
          key: "roles",
          icon: "fas fa-user-tag",
          to: "/roles",
          right: "GlobalAccess",
        },
        {
          key: "customers",
          icon: "fas fa-user-tie",
          to: "/customers",
          right: "GlobalAccess",
        },
        {
          key: "import",
          icon: "fas fa-file",
          to: "/import",
          right: "GlobalAccess",
        },
      ],
      loginRoute: {
        key: "login",
        icon: "fas fa-user",
        to: "/login",
      },
      logoutRoute: {
        key: "logout",
        icon: "fas fa-sign-out-alt",
      },
      allowedRoutes: [],
      user: null,
    };
  },

  mounted() {
    this.getCurrentUser();
    this.getLanguage();
  },

  computed: {
    mini() {
      return this.$vuetify.breakpoint.mdAndDown;
    }
  },

  methods: {
    checkDrawer(){
      if(this.navObject == null){
        this.drawer = false;
        return this.drawer;
      }else {
        this.drawer = true;
        return this.drawer;
      }
    },
    fetchEntries(){
      this.$refs.findings.searchEntry();
    },
    fetchUsers(){
      this.$refs.findings.fetchUsers();
    },
    removeDevice(userid,device){
      this.$refs.findings.confirmDeviceRemove(userid,device);
    },
    updateState(data) {
      if (data.name === "authentication") {
        if (data.type === "login") {
          this.getCurrentUser();
          this.$router.push("findings");
        }
      }
    },
    // showNav() {
    //   return window.location.pathname.includes("login") || window.location.pathname.includes("reset-password");
    // },
    reload(){
      this.$refs.fetchEntries.fetchEntries();
    },
    close(){
      if(this.navObject.type == 'findings'){
        this.$refs.findings.drawerClosed();
      }
      this.navObject = null;
    },
    showUserDetails(user){
      this.editUser = user;
      if(user.createmode){
        this.editUser.user = {
        id: null,
        name: "",
        personalData: {
          firstname: "",
          lastname: "",
          birthdate: "2021-01-12",
        },
        mail: "",
        password: "",
        confirmPassword: "",
        role: "",
        deviceIdentifierContainer: {
          deviceIdentifiers: [],
          maxDevices: 0,
        },
        rights: [],
        customer: null,
      };
      }
      this.navObject = user;
    },
    showDetails(entry){
      this.editEntry = entry
      if(entry.createmode){
        this.editEntry.entry = {
                id: null,
                title: "",
                description: "",
                measurements: {
                    diameter: 0,
                    width: 0,
                    totalLength: 0,
                    totalHeight: 0,
                    lengthOfBody: 0,
                    heightOfBody: 0,
                    totalWeight: 0,
                    fillingWeight: 0,
                },
                additionalInfo: {
                    nation: null,
                    periodOfUse: null,
                    drivingBands: [],
                    explosiveBoosters: [],
                    propellant: null,
                    documents: [],
                    form: null,
                    securityAdvices: [],
                    materials: [],
                    filledWith: [],
                },
                sources: [""],
                images: [],
                rawImages: {},
                previewImage: null,
            };
      }
      this.navObject = entry;
    },
    pullUserData(metadata){
      this.metadata = metadata;
    },
    pullData(metadata){
      console.log('Data received in App.vue:', metadata);
      this.metadata = metadata;
    },
    getEntryPopupTabs() {
        // if (this.filtermode) {
        //     return ["general", "measurements", "additionalInfo"];
        // }
        return ["general", "measurements", "additionalInfo", "sources"];
    },

    getCurrentUser() {
      progressIndicator.hidden = false;
      this.$axios
        .get("/api/me")
        .then(this.gotCurrentUser)
        .catch(this.requestFailed);
    },
    gotCurrentUser(response) {
      this.user = response.data;
      this.allowedRoutes = [];
      for (let i = 0; i < this.standardRoutes.length; i++) {
        const route = this.standardRoutes[i];
        if (route.right == null || this.hasRight(route.right)) {
          this.allowedRoutes.push(route);
        }
      }
    },
    requestFailed(error) {
      if (error.response.status === 401) {
        if (this.$router.currentRoute.path !== "/invitation" && this.$router.currentRoute.path !== "/reset-password") {
          this.$router.push("login");
        }
      } else {
        this.$noty.error('<i class="fas fa-minus-circle mr-3"></i>' + this.$t(error.response.data));
      }
      this.user = null;
    },
    requestEnded() {
      progressIndicator.hidden = true;
    },

    hasRight(right) {
      if (this.user.rights.includes(right)) return true;
      if (this.user.role != null) {
        return this.user.role.rights.includes(right);
      }
      return false;
    },

    logout() {
      this.switchToLoginPage();
      this.logoutMenu = false;
    },
    switchToLoginPage() {
      this.$axios.removeBearer();
      this.user = null;
      this.$router.push("login");
    },
    nothing() {},

    getLanguage() {
      this.$nextTick(() => {
        this.$i18n.locale = localStorage.getItem('locale');
      })
    }
  }
};
</script>
